import {useEffect, useMemo, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvents, setSelectedDate} from "../redux/eventsSlice";
import {
    addMonths,
    eachDayOfInterval,
    endOfMonth,
    format,
    formatISO,
    parse,
    parseISO,
    startOfMonth,
    subMonths,
    isSameMonth
} from "date-fns";
import {setPreferredDate} from "../redux/calendarSlice";
import {toZonedTime} from "date-fns-tz";

export const useCalendarLogic = () => {
    const dispatch = useDispatch();
    const selectedDateString = useSelector((state) => state.events?.selectedDate);
    const initialDate = new Date();
    const [firstDateInDisplayedMonth, setFirstDateInDisplayedMonth] = useState(startOfMonth(initialDate));
    const rsvpHasChanged = useSelector(state => state.events.rsvpChanged);
    const hasMounted = useRef(false);
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const [prevFirstDateInDisplayedMonth, setPrevFirstDateInDisplayedMonth] = useState(null);
    const events = useSelector((state) => state.events?.items ?? {});
    const getMonthKey = (date) => `${date.getFullYear()}-${date.getMonth() + 1}`;
    const preferredDatesByMonth = useSelector((state) => state.calendar.preferredDatesByMonth);
    const fetchEventsStatus = useSelector((state) => state.events.status);
    const timeZone = useSelector((state) => state.instrDetails?.instrDetails?.timezone ?? 'Etc/UTC');
    const padZero = (num) => String(num).padStart(2, '0');

    const selectedDate = useMemo(() => {
        if (selectedDateString) {
            return parse(selectedDateString, 'yyyy-MM-dd', new Date());
        }
        return null;
    }, [selectedDateString]);

    useEffect(() => {
        if (hasMounted.current) {
            if (instr_id && firstDateInDisplayedMonth) {
                dispatch(fetchEvents({
                    instr_id,
                    month: firstDateInDisplayedMonth.getMonth() + 1,
                    year: firstDateInDisplayedMonth.getFullYear(),
                }));
            }
        } else {
            hasMounted.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rsvpHasChanged]);

    const checkEventsInState = (year, month) => {
        return events[year] && events[year][month];
    }

    useEffect(() => {
        const year = firstDateInDisplayedMonth.getFullYear();
        const month = firstDateInDisplayedMonth.getMonth() + 1;  // Month is 0 indexed

        if (instr_id && prevFirstDateInDisplayedMonth !== firstDateInDisplayedMonth) {
            if (checkEventsInState(year, month)) {
                autoSelectDateForMonth();
            } else {
                dispatch(fetchEvents({
                    instr_id,
                    month: month,
                    year: year,
                }));
            }
            // TODO: return rejectWithValue(error.message) is ignored without .unwrap().then() (I think, research this)
            setPrevFirstDateInDisplayedMonth(firstDateInDisplayedMonth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstDateInDisplayedMonth]);

    useEffect(() => {
        if (fetchEventsStatus === 'succeeded') {
            autoSelectDateForMonth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchEventsStatus]);

    const autoSelectDateForMonth = () => {
        const optimalDate = findOptimalDateForMonth(firstDateInDisplayedMonth);
        let isoDate;
        if (optimalDate) {
            isoDate = formatISO(optimalDate, {representation: 'date'});
        } else if (isSameMonth(firstDateInDisplayedMonth, new Date())) {
            // We're in the current month and no optimal date is found, select today
            isoDate = formatISO(new Date(), {representation: 'date'});
        } else {
            // No events in the rest of the given month, select the first day of the month
            isoDate = formatISO(startOfMonth(firstDateInDisplayedMonth), {representation: 'date'});
        }
        dispatch(setSelectedDate(isoDate));
        return isoDate;
    }

    const findOptimalDateForMonth = (firstDateInDisplayedMonth) => {
        let optimalDate;
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const monthKey = getMonthKey(firstDateInDisplayedMonth);
        if (preferredDatesByMonth[monthKey]) {
            return new Date(parse(preferredDatesByMonth[monthKey], 'yyyy-MM-dd', new Date()));
        }

        if (firstDateInDisplayedMonth.getFullYear() !== currentYear || firstDateInDisplayedMonth.getMonth() !== currentMonth) {
            optimalDate = findFirstEventDateInMonth(firstDateInDisplayedMonth);
        } else {
            optimalDate = findNextEventDateInCurrentMonth();
        }

        return optimalDate;
    };

    const findFirstEventDateInMonth = (monthDate) => {
        const start = startOfMonth(monthDate);
        const end = endOfMonth(monthDate);
        const daysInCurrentMonth = eachDayOfInterval({start, end});
        for (const day of daysInCurrentMonth) {
            const formattedDayDate = format(day, 'yyyy-MM-dd');
            if (day >= monthDate && hasEvent(formattedDayDate)) {
                return new Date(day);
            }
        }
        return null;
    };

    const findNextEventDateInCurrentMonth = () => {
        const now = new Date();
        const end = endOfMonth(now);
        const daysInCurrentMonth = eachDayOfInterval({start: now, end});
        for (const day of daysInCurrentMonth) {
            const formattedDayDate = format(day, 'yyyy-MM-dd');
            if (day >= now && hasEvent(formattedDayDate)) {
                return new Date(day);
            }
        }
        return null;
    };

    const hasEvent = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const yearEvents = events[year];
        if (!yearEvents) return false;

        const monthEvents = yearEvents[month];
        if (!monthEvents) return false;

        const dayEvents = monthEvents[day];
        if (!dayEvents || dayEvents.length === 0) return false;

        return dayEvents.some(event => {
            const eventDateOnly = format(parseISO(event.start_time), 'yyyy-MM-dd');
            return eventDateOnly === dateString;
        });
    };

    const generateDays = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDayOfMonth = new Date(year, month, 1);
        const lastDayOfPrevMonth = new Date(year, month, 0);

        const daysArray = [];

        // Fill in days from the previous month
        for (let i = firstDayOfMonth.getDay() - 1; i >= 0; i--) {
            const precedingDate = new Date(lastDayOfPrevMonth);
            precedingDate.setDate(lastDayOfPrevMonth.getDate() - i);
            daysArray.push({
                date: precedingDate,
            });
        }

        // Fill in days for the current month
        const lastDayOfMonth = new Date(year, month + 1, 0);
        for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
            daysArray.push({
                date: new Date(year, month, i),
                isCurrentMonth: true,
            });
        }

        // Fill in days for the next month
        const subsequentDaysNeeded = 6 - lastDayOfMonth.getDay();
        for (let i = 1; i <= subsequentDaysNeeded; i++) {
            const subsequentDate = new Date(year, month + 1, i);
            daysArray.push({
                date: subsequentDate,
            });
        }
        return daysArray;
    };

    const [days, setDays] = useState(generateDays(initialDate));

    const handleNextMonth = () => {
        setFirstDateInDisplayedMonth(prevMonth => {
            const nextMonth = startOfMonth(addMonths(prevMonth, 1));
            setDays(generateDays(nextMonth));
            return nextMonth;
        });
    };

    const handlePrevMonth = () => {
        setFirstDateInDisplayedMonth(prevMonth => {
            const previousMonth = startOfMonth(subMonths(prevMonth, 1));
            setDays(generateDays(previousMonth));
            return previousMonth;
        });
    };

    const isToday = (date) => {
        const today = toZonedTime(new Date(), timeZone);
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const handleDateClick = (date) => {
        const isoDate = formatISO(date, {representation: 'date'});
        dispatch(setSelectedDate(isoDate));
        // User specifically selecting date, let's remember it.
        dispatch(setPreferredDate({
            monthKey: getMonthKey(date),
            date: isoDate,
        }));
    };

    const eventsForDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const paddedMonth = padZero(month);
        const paddedDay = padZero(day);

        if (
            events[year] &&
            events[year][paddedMonth] &&
            events[year][paddedMonth][day]
        ) {
            return events[year][paddedMonth][paddedDay];
        }
        return [];
    };

    const filteredEvents = useMemo(() => {
        if (!selectedDateString) return [];
        return eventsForDate(selectedDateString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, selectedDateString]);

    return {
        firstDateInDisplayedMonth,
        selectedDate,
        selectedDateString,
        hasEvent,
        days,
        handleNextMonth,
        handlePrevMonth,
        isToday,
        handleDateClick,
        filteredEvents
    };
}

export const formatEventTimeRange = (startTime, endTime) => {
    const [startDate, startTimePart] = startTime.split('T');
    const [endDate, endTimePart] = endTime.split('T');

    const [startYear, startMonth, startDay] = startDate.split('-');
    const [startHour, startMinute] = startTimePart.split(':');

    const [endYear, endMonth, endDay] = endDate.split('-');
    const [endHour, endMinute] = endTimePart.split(':');

    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const formattedDateStart = new Intl.DateTimeFormat('en-US', options).format(new Date(startYear, startMonth - 1, startDay));
    const formattedDateEnd = new Intl.DateTimeFormat('en-US', options).format(new Date(endYear, endMonth - 1, endDay));

    const formattedTimeStart = `${parseInt(startHour, 10) % 12 || 12}:${startMinute} ${parseInt(startHour, 10) < 12 ? 'AM' : 'PM'}`;
    const formattedTimeEnd = `${parseInt(endHour, 10) % 12 || 12}:${endMinute} ${parseInt(endHour, 10) < 12 ? 'AM' : 'PM'}`;

    if (startDate === endDate) {
        return `${formattedDateStart} from ${formattedTimeStart} to ${formattedTimeEnd}`;
    } else {
        return `${formattedDateStart} at ${formattedTimeStart} to ${formattedDateEnd} at ${formattedTimeEnd}`;
    }
};
