import {Combobox} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {FiDelete} from "react-icons/fi";
import {MdAddCircleOutline, MdCancel, MdEventAvailable, MdEventBusy, MdSave} from "react-icons/md";
import React, {useState} from "react";
import {useSelector} from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ParticipationCriteriaForm = ({formData, setFormData, formErrors, setFormStatus}) => {
    const customerTypes = useSelector((state) => state.customerTypes?.customerTypes);
    const [customerTypesQuery, setCustomerTypesQuery] = useState('');
    const courses = useSelector((state) => state.courses?.courses);
    const [coursesQuery, setCoursesQuery] = useState('')
    const [isAddingForm, setIsAddingForm] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const allForms = useSelector((state) => state.forms?.forms ?? []);
    const [formsQuery, setFormsQuery] = useState('');

    const filteredCourses =
        coursesQuery === ''
            ? courses
            : courses.filter((course) => {
                return course.name  // + ' (' + course.org + ')'
                    ? course.name.toLowerCase().includes(coursesQuery.toLowerCase())
                    : false;
            })


    const handleNestedComboChange = (fields, index) => {
        const newCriteria = JSON.parse(JSON.stringify(formData.participation_criteria)); // Deep-clone
        Object.keys(fields).forEach((field) => {
            newCriteria[index][field] = fields[field];
        });
        setFormData({
            ...formData,
            participation_criteria: newCriteria
        });
        setFormStatus(null);
    };

    const handleNestedChange = (field, value, index) => {
        const newCriteria = JSON.parse(JSON.stringify(formData.participation_criteria)); // Deep-clone
        newCriteria[index][field] = value;
        setFormData({
            ...formData,
            participation_criteria: newCriteria
        });
        setFormStatus(null);
    };

    const filteredCustomerTypes =
        customerTypesQuery === ''
            ? customerTypes
            : customerTypes.filter((customer_type) => {
                return customer_type.customer_type
                    ? customer_type.customer_type.toLowerCase().includes(customerTypesQuery.toLowerCase())
                    : false;
            })

    const filteredForms =
        formsQuery === ''
            ? allForms
            : allForms.filter((form) => {
                return form.form_id
                    ? form.short_name.toLowerCase().includes(formsQuery.toLowerCase())
                    : false;
            })

    const setDefaultFormsForCourse = (selectedCourse, index) => {
        const defaultForms = (selectedCourse.forms || []).filter(form => form.form_id && !form.on_completion && !form.instr_first);
        const newCriteria = JSON.parse(JSON.stringify(formData.participation_criteria)); // Deep-clone
        newCriteria[index].forms = defaultForms;

        setFormData({
            ...formData,
            participation_criteria: newCriteria
        });
    };

    const handleCancelAddForm = () => {
        setIsAddingForm(false);
        setSelectedForm(null);
    };

    const handleSaveForm = (criteriaIndex) => {
        // console.log(formData);
        if (selectedForm) {
            const newCriteria = JSON.parse(JSON.stringify(formData.participation_criteria)); // Deep-clone

            if (!newCriteria[criteriaIndex].forms) {
                newCriteria[criteriaIndex].forms = [];
            }

            const isDuplicate = newCriteria[criteriaIndex].forms.some(form => form.form_id === selectedForm.form_id);
            if (!isDuplicate) {
                newCriteria[criteriaIndex].forms.push(selectedForm); // Add the new form
                setFormData({
                    ...formData,
                    participation_criteria: newCriteria
                });
            }

            setSelectedForm(null); // Reset the selected form
            setFormsQuery('');
        }
    };


    const handleRemoveCustomerType = (index) => {
        setFormData(prevFormData => {
            const newCriteria = [...prevFormData.participation_criteria];
            newCriteria.splice(index, 1);
            return {
                ...prevFormData,
                participation_criteria: newCriteria
            };
        });
        setFormStatus(null);
        console.log(formData);
    };

    const handleAddCustomerType = () => {
        setFormData(prevFormData => {
            const newCriteria = [...prevFormData.participation_criteria];
            newCriteria.push({
                customer_type: '',
                price: 0,
                max_people: 1,
                min_people: 0,
                gear_factor: 1,
                prerequisite_course_code: '',
                prerequisite_course_name: '',
                active_course_code: '',
                active_course_name: '',
                event_course_code: '',
                event_course_name: '',
                forms: null,
                type_id: null  // TODO: Refactor to use epc_id
            });
            return {
                ...prevFormData,
                participation_criteria: newCriteria
            };
        });
    };

    const handleRemoveForm = (criteriaIndex, formId) => {
        const newCriteria = JSON.parse(JSON.stringify(formData.participation_criteria)); // Deep-clone
        if (newCriteria[criteriaIndex].forms) {
            const formIndex = newCriteria[criteriaIndex].forms.findIndex(form => form.form_id === formId);

            if (formIndex > -1) {
                newCriteria[criteriaIndex].forms.splice(formIndex, 1);
            }

        }
        setFormData({
            ...formData,
            participation_criteria: newCriteria
        });
    };

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
                Establish Participation Criteria
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Price is specific to this calendar event and is in addition to any cost associated with
                the <u>active</u> course, if applicable.<br/><br/>
                The <u>prerequisite</u> course is a course they must have completed prior to participating in
                this
                event.
                The <u>active</u> course is the course they must be currently enrolled in to
                participate.
                The <u>event</u> course is the advertised course associated with this event, but not does
                require
                a paid enrollment separate from event registration. Some examples:
                An Open Water (OW) diver participating in an Advanced Open Water (AOW) course would have
                OW for <u>prerequisite</u>, AOW for <u>active</u>, and AOW for <u>event</u>.
                A Divemaster leading a Discover Local
                Diving (DLD) event would have a Divemaster <u>prerequisite</u>, nothing for <u>active</u>,
                and DLD for <u>event</u>.
                A charter for Fun Diver's with no associated event course may have Open Water
                for <u>prerequisite</u>,
                nothing for <u>active</u>, and nothing for <u>event</u>. This is where the price field comes in
                handy.
                A Divemaster Candidate (DMC) intending to assist an OW course as part of their DMC skill
                training
                may have Rescue Diver <u>prerequisite</u>, Divemaster for <u>active</u>, and OW for <u>event</u>.
                <br/><br/>
                Gear Factor allows you to customize the maximize number of participants based on customer
                type. Perhaps you have room for 10 people on the boat, but not 10 divers with all their gear.
                Setting a Gear Factor of 1.0 for Non-Divers and 1.5 for Divers would give you possible
                combinations of 6 divers and 1 non-diver, 5 divers and 2 non-divers, 4 divers and 4 non-divers,
                3 divers and 5 non-divers, 2 divers and 7 non-divers, 1 diver and 8 non-divers, or 10
                non-divers.
                Fine tune the Gear Factors to accommodate your specific situation. You can also put an individual
                minimum and maximum on each customer type. Minimum's are used to 'hold seats' and set aside
                space for gear to avoid a boat full of non-divers and no space left for a diver. Likewise, you
                could set a Staff position to Min People of 1 to avoid too many Divers signing up, taking all
                the seats before you assign a Staff member to the event.
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {formData.participation_criteria?.map((criteria, index) => (
                    <div key={index} className="sm:col-span-2">
                        <Combobox as="div"
                                  value={criteria.customer_type || ''}
                                  onChange={(value) => {
                                      const selectedCustomerType = customerTypes.find(type => type.customer_type === value);
                                      if (selectedCustomerType) {
                                          handleNestedComboChange({
                                              type_id: selectedCustomerType.type_id,
                                              customer_type: selectedCustomerType.customer_type
                                          }, index);
                                      }
                                  }}
                        >
                            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer Type
                            </Combobox.Label>
                            <div className="relative mb-2">
                                <Combobox.Input
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(event) => setCustomerTypesQuery(event.target.value)}
                                    displayValue={(type) => type}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Combobox.Button>

                                {filteredCustomerTypes.length > 0 && (
                                    <Combobox.Options
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredCustomerTypes.map((type) => (
                                            <Combobox.Option
                                                key={type.type_id}
                                                value={type.customer_type}
                                                className={({focus: isFocused}) =>
                                                    classNames(
                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                        isFocused ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                    )
                                                }
                                            >
                                                {({focus: isFocused, selected}) => (
                                                    <>
                                                                <span
                                                                    className={classNames('block truncate', selected && 'font-semibold')}>
                                                                    {type.customer_type}
                                                                </span>

                                                        {selected && (
                                                            <span
                                                                className={classNames(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    isFocused ? 'text-white' : 'text-indigo-600'
                                                                )}
                                                            >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                        <label htmlFor="price"
                               className={`block text-sm font-medium leading-6 ${formErrors[`price_${index}`] ? 'text-red-900' : 'text-gray-900'}`}>
                            Price
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div
                                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="number"
                                name={`price_${index}`}
                                id={`price_${index}`}
                                value={criteria.price || 0}
                                onChange={(e) => handleNestedChange('price', parseFloat(e.target.value), index)}
                                className={`mb-2 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 ${formErrors[`price_${index}`] ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                aria-describedby="price-currency"
                            />
                            <div
                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                          <span className="text-gray-500 sm:text-sm" id="price-currency">
                                            USD
                                          </span>
                            </div>
                        </div>
                        <label htmlFor="max_people"
                               className={`block text-sm font-medium leading-6 ${formErrors[`max_people_${index}`] ? 'text-red-900' : 'text-gray-900'}`}>
                            Max People
                        </label>
                        <div>
                            <input
                                type="number"
                                min="0"
                                step="1"
                                name={`max_people_${index}`}
                                id={`max_people_${index}`}
                                value={criteria.max_people || 0}
                                onChange={(e) => handleNestedChange('max_people', e.target.value, index)}
                                className={`mb-2 block w-full rounded-md border-0 py-1.5 ${formErrors[`max_people_${index}`] ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                            />
                        </div>
                        <label htmlFor="min_people"
                               className={`block text-sm font-medium leading-6 ${formErrors[`min_people_${index}`] ? 'text-red-900' : 'text-gray-900'}`}>
                            Min People
                        </label>
                        <div>
                            <input
                                type="number"
                                min="0"
                                step="1"
                                name={`min_people_${index}`}
                                id={`min_people_${index}`}
                                value={criteria.min_people || 0}
                                onChange={(e) => handleNestedChange('min_people', e.target.value, index)}
                                className={`mb-2 block w-full rounded-md border-0 py-1.5 ${formErrors[`min_people_${index}`] ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                            />
                        </div>
                        <label htmlFor="gear_factor"
                               className={`block text-sm font-medium leading-6 ${formErrors[`gear_factor_${index}`] ? 'text-red-900' : 'text-gray-900'}`}>
                            Gear Factor
                        </label>
                        <div>
                            <input
                                type="number"
                                name={`gear_factor_${index}`}
                                id={`gear_factor_${index}`}
                                value={criteria.gear_factor || 0}
                                onChange={(e) => handleNestedChange('gear_factor', parseFloat(e.target.value), index)}
                                className={`mb-2 block w-full rounded-md border-0 py-1.5 ${formErrors[`gear_factor_${index}`] ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                            />
                        </div>
                        <Combobox as="div"
                                  value={criteria.prerequisite_course_name || ''}
                                  onChange={(value) => {
                                      if (value === "") {
                                          handleNestedComboChange({
                                              prerequisite_course_code: "",
                                              prerequisite_course_name: ""
                                          }, index);
                                      } else {
                                          const selectedCourse = courses.find(course => course.name === value);
                                          if (selectedCourse) {
                                              handleNestedComboChange({
                                                  prerequisite_course_code: selectedCourse.code,
                                                  prerequisite_course_name: selectedCourse.name
                                              }, index);
                                          }
                                      }
                                  }}
                        >
                            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Prerequisite Course
                            </Combobox.Label>
                            <div className="relative mb-2">
                                <Combobox.Input
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(event) => setCoursesQuery(event.target.value)}
                                    displayValue={(course) => course}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Combobox.Button>

                                {filteredCourses.length > 0 && (
                                    <Combobox.Options
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <Combobox.Option
                                            key="blank_option"
                                            value=""
                                            className={({focus}) =>
                                                classNames('relative cursor-default select-none py-2 pl-3 pr-9', focus ? 'bg-indigo-600 text-white' : 'text-gray-900')
                                            }
                                        >
                                            {() => (
                                                <>
                                                    <span className="block truncate">(Clear Selection)</span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                        {filteredCourses.map((course) => (
                                            <Combobox.Option
                                                key={course.code}
                                                value={course.name}
                                                className={({focus}) =>
                                                    classNames(
                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                        focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                    )
                                                }
                                            >
                                                {({focus, selected}) => (
                                                    <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{course.name}</span>

                                                        {selected && (
                                                            <span
                                                                className={classNames(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    focus ? 'text-white' : 'text-indigo-600'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                        )}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                        <Combobox as="div"
                                  value={criteria.active_course_name || ''}
                                  onChange={(value) => {
                                      if (value === "") {
                                          handleNestedComboChange({
                                              active_course_code: "",
                                              active_course_name: ""
                                          }, index);
                                      } else {
                                          const selectedCourse = courses.find(course => course.name === value);
                                          if (selectedCourse) {
                                              handleNestedComboChange({
                                                  active_course_code: selectedCourse.code,
                                                  active_course_name: selectedCourse.name
                                              }, index);
                                          }
                                      }
                                  }}
                                  onSelect={(event) => {
                                      const value = event.target.value;
                                      const selectedCourse = courses.find(course => course.name === value);
                                      if (selectedCourse) {
                                          setDefaultFormsForCourse(selectedCourse, index);
                                      }
                                  }}
                        >
                            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Active Course
                            </Combobox.Label>
                            <div className="relative mb-2">
                                <Combobox.Input
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(event) => setCoursesQuery(event.target.value)}
                                    displayValue={(course) => course}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Combobox.Button>

                                {filteredCourses.length > 0 && (
                                    <Combobox.Options
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <Combobox.Option
                                            key="blank_option"
                                            value=""
                                            className={({focus}) =>
                                                classNames('relative cursor-default select-none py-2 pl-3 pr-9', focus ? 'bg-indigo-600 text-white' : 'text-gray-900')
                                            }
                                        >
                                            {() => (
                                                <>
                                                    <span className="block truncate">(Clear Selection)</span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                        {filteredCourses.map((course) => (
                                            <Combobox.Option
                                                key={course.code}
                                                value={course.name}
                                                className={({focus}) =>
                                                    classNames(
                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                        focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                    )
                                                }
                                            >
                                                {({focus, selected}) => (
                                                    <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{course.name}</span>

                                                        {selected && (
                                                            <span
                                                                className={classNames(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    focus ? 'text-white' : 'text-indigo-600'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                        )}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                        <Combobox as="div"
                                  value={criteria.event_course_name || ''}
                                  onChange={(value) => {
                                      if (value === "") {
                                          handleNestedComboChange({
                                              event_course_code: "",
                                              event_course_name: ""
                                          }, index);
                                      } else {
                                          const selectedCourse = courses.find(course => course.name === value);
                                          if (selectedCourse) {
                                              handleNestedComboChange({
                                                  event_course_code: selectedCourse.code,
                                                  event_course_name: selectedCourse.name
                                              }, index);
                                          }
                                      }
                                  }}
                        >
                            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Event Course
                            </Combobox.Label>
                            <div className="relative mb-2">
                                <Combobox.Input
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(event) => setCoursesQuery(event.target.value)}
                                    displayValue={(course) => course}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Combobox.Button>

                                {filteredCourses.length > 0 && (
                                    <Combobox.Options
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <Combobox.Option
                                            key="blank_option"
                                            value=""
                                            className={({focus}) =>
                                                classNames('relative cursor-default select-none py-2 pl-3 pr-9', focus ? 'bg-indigo-600 text-white' : 'text-gray-900')
                                            }
                                        >
                                            {() => (
                                                <>
                                                    <span className="block truncate">(Clear Selection)</span>
                                                </>
                                            )}
                                        </Combobox.Option>
                                        {filteredCourses.map((course) => (
                                            <Combobox.Option
                                                key={course.code}
                                                value={course.name}
                                                className={({focus}) =>
                                                    classNames(
                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                        focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                    )
                                                }
                                            >
                                                {({focus, selected}) => (
                                                    <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{course.name}</span>

                                                        {selected && (
                                                            <span
                                                                className={classNames(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    focus ? 'text-white' : 'text-indigo-600'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                        )}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                        <label htmlFor={`forms_${index}`}
                               className={`block text-sm font-medium leading-6 ${formErrors[`forms_${index}`] ? 'text-red-900' : 'text-gray-900'}`}>
                            Forms
                        </label>
                        {criteria.forms && criteria.forms.map((form) => (
                            <button
                                key={form.form_id}
                                type="button"
                                className="mr-2 mb-2 inline-flex items-center gap-x-1.5 rounded-md bg-gray-300 px-2 py-1 text-sm font-semibold text-black shadow-sm hover:bg-gray-400"
                                onClick={() => handleRemoveForm(index, form.form_id)}
                            >
                                {form.short_name}
                                <FiDelete className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                            </button>
                        ))}
                        {isAddingForm ? (
                            <>
                                <div className="flex w-full" key={`add-form-${index}`}>
                                    <div className="flex-grow">
                                        <Combobox as="div" value={selectedForm?.short_name || ''}
                                                  onChange={(newVal) => {
                                                      const selectedForm = filteredForms.find(form => form.short_name === newVal);
                                                      if (selectedForm) {
                                                          // console.log(selectedForm);
                                                          setSelectedForm(selectedForm);
                                                      }
                                                  }}
                                        >
                                            <div className="relative mt-2">
                                                <Combobox.Input
                                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(form) => setFormsQuery(form.target.value)}
                                                    displayValue={(form) => form}
                                                />
                                                <Combobox.Button
                                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400"
                                                                       aria-hidden="true"/>
                                                </Combobox.Button>

                                                {filteredForms?.length > 0 && (
                                                    <Combobox.Options
                                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                                                        {filteredForms.map((form) => (
                                                            <Combobox.Option
                                                                key={form.id}
                                                                value={form.short_name}
                                                                className={({focus}) =>
                                                                    classNames(
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                                        focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                                    )
                                                                }
                                                            >
                                                                {({focus, selected}) => (
                                                                    <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{form.short_name}</span>
                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                                    focus ? 'text-white' : 'text-indigo-600'
                                                                                )}
                                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Options>
                                                )}
                                            </div>
                                        </Combobox>
                                    </div>
                                    <div className="flex-shrink-0 flex items-center justify-center">
                                        <button
                                            type="button"
                                            className="p-1.5 rounded text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                                            onClick={() => handleSaveForm(index)}
                                        >
                                            <MdSave className="h-5 w-5"/>
                                        </button>
                                        <button
                                            onClick={handleCancelAddForm}
                                            className="p-1.5 rounded text-gray-700 hover:text-gray-900 hover:bg-gray-200"
                                        >
                                            <MdCancel className="h-5 w-5"/>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <button
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 text-white px-2 py-1 text-sm font-semibold shadow-sm hover:bg-indigo-500"
                                onClick={() => setIsAddingForm(true)}
                            >
                                Add Form
                                <MdAddCircleOutline className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                            </button>
                        )}
                        <div>
                            <button
                                type="button"
                                className="mt-3 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={() => handleRemoveCustomerType(index)}
                            >
                                <MdEventBusy className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Remove Customer Type
                            </button>
                        </div>
                    </div>
                ))}
                <div className="sm:col-span-2 mt-6">
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleAddCustomerType}
                    >
                        <MdEventAvailable className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                        Add Customer Type
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ParticipationCriteriaForm;