import React, {Fragment, useState, useRef, useEffect} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link} from "react-router-dom";
import {signOut as signOutAction, updateImageTime} from "../redux/authSlice";
import {signOut} from "aws-amplify/auth";
import {useSelector, useDispatch} from 'react-redux';
import {resetOutstandingData} from "../redux/outstandingDataSlice";
import {getImageUrl} from "../utils/sanitizeFunctions";


const Header = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const instrId = useSelector((state) => state.auth.userDetails?.instr_id);
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const menuRef = useRef(null);
    const isInstrOfScubaDomain = (window.location.hostname === 'instructorofscuba.com') || (window.location.hostname === 'localhost');
    const [portraitImageUrl, setPortraitImageUrl] = useState('/images/portraits/unknown_male.png');
    const [isOpen, setIsOpen] = useState(false);
    const studentId = useSelector((state) => state.auth.userDetails?.id);
    const imageUpdatedTime = useSelector((state) => state.auth.imageUpdatedTime);

    useEffect(() => {
        if (isAuthenticated) {
            if (studentId && studentId !== 'undefined') {
                const baseImageUrl = getImageUrl(`userPortrait-${studentId}.jpeg`);
                setPortraitImageUrl(`${baseImageUrl}?${imageUpdatedTime}`);
            }
        }
    }, [dispatch, isAuthenticated, studentId, imageUpdatedTime]);

    const handleSignOut = async (event) => {
        event.preventDefault();
        try {
            await signOut();
            dispatch(signOutAction());
            dispatch(resetOutstandingData());
        } catch (error) {
            setErrorInfo('UnlistedDomainHeader-handleSignOut\n' + error);
        }
    }

    const userNavigation = isAuthenticated ? (
        instrId ? [
            {name: 'Student Profile', to: '/profile'},
            {name: 'Instructor Profile', to: '/instructor-profile'},
            {name: 'Manage Subscription', to: '/manage-subscription'},
            // {name: 'Enrollments', href: '#'},
            {name: 'Sign out', onClick: (event) => handleSignOut(event)}
        ] : isInstrOfScubaDomain ? [
            {name: 'Update Profile', to: '/instructor-profile'},
            {name: 'Manage Subscription', to: '/manage-subscription'},
            {name: 'Sign out', onClick: (event) => handleSignOut(event)}
        ] : [
            {name: 'Update Profile', to: '/profile'},
            {name: 'Enrollments', href: '#'},
            {name: 'Sign out', onClick: (event) => handleSignOut(event)}
        ]
    ) : [
        {name: 'Sign In', to: '/signin'},
    ]

    let header_logo = '/favicon/android-chrome-512x512.png';

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <>
            <div data-testid="unlisted-domain-header" className="min-h-full z-50">
                <Disclosure as="nav" className="bg-gray-800" open={isOpen} onChange={() => setIsOpen(!isOpen)}>
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link to="/">
                                                <img
                                                    className="h-8"
                                                    src={header_logo}
                                                    alt="Logo"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="text-white">
                                        Instructor of Scuba
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <button
                                                type="button"
                                                className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                            >
                                                <span className="sr-only">View notifications</span>
                                                <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>

                                            {/* Profile dropdown */}
                                            <Menu as="div" className="relative ml-3" ref={menuRef}>
                                                <div>
                                                    <Menu.Button
                                                        className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 text-gray-400 hover:text-white">
                                                        <span className="sr-only">Open user menu</span>
                                                        {isAuthenticated ? (
                                                            <img
                                                                src={portraitImageUrl}
                                                                alt="User Thumbnail" className="h-6 w-6 rounded-full"
                                                                onError={(e) => {
                                                                    if (e.target.src !== '/images/portraits/unknown_male.png') {
                                                                        e.target.src = '/images/portraits/unknown_male.png';
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <Bars3Icon
                                                                className="h-6 w-6 rounded-full"/>
                                                        )}
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({focus}) => (
                                                                    <Link
                                                                        to={item.to}
                                                                        onClick={(event) => {
                                                                            setIsOpen(false);
                                                                            item.onClick && item.onClick(event);
                                                                        }}
                                                                        className={`${focus ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                                                    >
                                                                        {item.name}
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="border-t border-gray-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            {isAuthenticated && (
                                                <img src={portraitImageUrl}
                                                     alt="User Thumbnail" className="h-6 w-6 rounded-full"
                                                     onError={(e) => {
                                                         if (e.target.src !== '/images/portraits/unknown_male.png') {
                                                             e.target.src = '/images/portraits/unknown_male.png';
                                                         }
                                                     }}
                                                />
                                            )
                                            }
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.to}
                                                onClick={(event) => {
                                                    setIsOpen(false);
                                                    item.onClick && item.onClick(event);
                                                }}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

            </div>
        </>
    )
}

export default (Header);
