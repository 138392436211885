import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async (_, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-courses';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

const coursesSlice = createSlice({
    name: 'courses',
    initialState: {
        courses: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.courses = action.payload;
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default coursesSlice.reducer;
