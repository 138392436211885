import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';
import {fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';

export const checkUser = createAsyncThunk('auth/checkUser', async (_, thunkAPI) => {
    try {
        const currentUser = await getCurrentUser();
        const loginEmail = currentUser.signInDetails.loginId;

        const existingUserDetails = thunkAPI.getState().auth.userDetails;
        if (!existingUserDetails) {
            await thunkAPI.dispatch(fetchUserDetails({}));
        }

        return {
            isAuthenticated: true,
            loginEmail,
        };
    } catch (error) {
        return thunkAPI.rejectWithValue({error: error.message, isAuthenticated: false});
    }
});

export const refreshToken = createAsyncThunk('auth/refreshToken', async (_, thunkAPI) => {
    try {
        await fetchAuthSession();
        thunkAPI.dispatch(checkUser());
    } catch (error) {
        console.log('refreshToken: ', error.message);
        return thunkAPI.rejectWithValue({error: error.message, isAuthenticated: false});
    }
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userDetails: null,
        imageUpdatedTime: null,
        isAuthenticated: false,
        loginEmail: null,
        error: null,
    },
    reducers: {
        signOut: (state) => {
            state.userDetails = null;
            state.isAuthenticated = false;
            state.loginEmail = null;
            state.error = null;
        },
        addUserCertification: (state, action) => {
            const newCert = {
                course_code: action.payload,
                cert_date: null,
                cert_number: null
            };
            state.userDetails.certifications = [...state.userDetails.certifications, newCert];
        },
        addCourseRegistration: (state, action) => {
            const newReg = {
                instr_id: action.payload.instr_id,
                course_code: action.payload.course_code,
                registration_date: action.payload.registrationDate,
                payment_status: 'Not Paid',
                payment_receipt_num: null,
                forms_complete: false,
                academics_type: null,
                academics_complete: false,
                dives_complete: false,
                c_card_status: "Not Submitted",
                c_card_num: null,
                course_name: action.payload.course_name,
            };
            if (!state.userDetails.course_registrations) {
                state.userDetails.course_registrations = [];
            }
            state.userDetails.course_registrations.push(newReg);
        },
        updateImageTime: (state) => {
            state.imageUpdatedTime = new Date().getTime();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkUser.fulfilled, (state, action) => {
                state.isAuthenticated = action.payload.isAuthenticated;
                state.loginEmail = action.payload.loginEmail;
                state.error = null;
            })
            .addCase(checkUser.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.loginEmail = null;
                state.error = action.payload.error;
                state.userDetails = null;
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.userDetails = action.payload;
            })
            .addCase(fetchUserDetails.rejected, (state, action) => {
                state.userDetails = null;
                if (action.payload === 'Student profile does not exist in DB.') {
                    console.log('Student profile for authenticated user does not yet exist in DB.');
                } else {
                    state.error = action.payload;
                }
            })
        ;
    },
});

export const fetchUserDetails = createAsyncThunk('auth/fetchUserDetails', async (_, {rejectWithValue}) => {
    const apiName = 'InstrOfScubaAPI'
    const path = '/getstudentinfo'
    const options = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    }
    try {
        const {body} = await get({apiName, path, options}).response;
        const data = await body.json();
        if (data.id === null) {
            return rejectWithValue('Student profile does not exist in DB.');
        } else {
            return data;
        }
    } catch (error) {
        // console.log(error);
        return rejectWithValue(error.message);
    }
})

export const {
    signOut,
    addUserCertification,
    addCourseRegistration
} = authSlice.actions;

export const { updateImageTime} = authSlice.actions;
export default authSlice.reducer;
