import React, {useEffect, useRef, useState} from 'react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Combobox} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {MdSave, MdCancel} from "react-icons/md";
import {post} from "aws-amplify/api";
import ParticipationCriteriaForm from "../components/ParticipationCriteriaForm";
import {convertToBase64} from "./sharedHooks";
import {BounceLoader} from "react-spinners";
import {fetchEventTemplates} from "../redux/eventTemplatesSlice";
import {getImageUrl} from "../utils/sanitizeFunctions";

const ManageEventTemplates = ({onExitManageEventTemplates}) => {
    const [errorInfo, setErrorInfo] = useState(null);
    const eventTemplates = useSelector((state) => state.eventTemplates?.eventTemplates);
    const [query, setQuery] = useState('')
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id ?? null);
    const [selectedEvent, setSelectedEvent] = useState("New Template");
    // const [isNewEvent, setIsNewEvent] = useState(true);
    const [formStatus, setFormStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sliceError = useSelector((state) => state.eventTemplates.error);
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const fileInput = useRef(null);
    const timeZone = useSelector((state) => state.instrDetails?.instrDetails?.timezone ?? 'Etc/UTC');
    const [newPhotoPending, setNewPhotoPending] = useState(false);
    const [eventImage, setEventImage] = useState('/images/generic-event.webp');
    const dispatch = useDispatch();

    const handleCheckedChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.checked});
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const uploadPhoto = () => {
        fileInput.current.click();
    }

    const [formData, setFormData] = useState({
        event_template_id: -1,  // -1 will indicate new event template to the backend save function.
        instr_title: '',
        customer_title: '',
        start_time: '',
        end_time: '',
        participation_criteria: [],
        description: '',
        image_url: 'generic-event.webp',
        image_data: '',
        customer_provided_gear: '',
        instr_provided_gear: '',
        optional_gear: '',
        dives: '',
        faq: '',
        home_carousel: false,
        allow_multiple_discounts: false,
        global_max_people: 6
    });

    // TODO: Need to check for invalid image_data. If it's invalid, it will show the default, but it won't tell the
    //  user there was a problem with the image they uploaded.
    useEffect(() => {
        if (newPhotoPending) {
            setEventImage(formData.image_data);
        } else if (formData.event_template_id === -1) {
            setEventImage('/images/generic-event.webp');
        } else if (formData.image_url === '') {
            setEventImage('/images/generic-event.webp');
        } else {
            setEventImage(getImageUrl(formData.image_url));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.image_data, formData.image_url, newPhotoPending])

    const [formErrors, setFormErrors] = useState({
        title: null,
        start_time: null,
        end_time: null,
        participation_criteria: null,
        description: null,
        image_url: null,
        customer_provided_gear: null,
        instr_provided_gear: null,
        optional_gear: null,
        dives: null,
        faq: null,
        home_carousel: null,
        allow_multiple_discounts: null,
        global_max_people: null
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        setFormStatus(null);
    };

    const handleEventComboChange = (newEvent) => {
        setSelectedEvent(newEvent);

        // if (newEvent === 'New Template') {
        //     setIsNewEvent(true);
        // } else {
        //     setIsNewEvent(false);
        // }

        // Find the full eventTemplate object from the locations array
        const eventObj = eventTemplates.find(et => et.instr_title === newEvent);

        if (eventObj) {
            setFormData({
                ...formData,
                event_template_id: eventObj.event_template_id,
                instr_title: eventObj.instr_title,
                customer_title: eventObj.customer_title,
                start_time: eventObj.start_time,
                end_time: eventObj.end_time,
                timezone: eventObj.timezone,
                participation_criteria: eventObj.participation_criteria,
                description: eventObj.description,
                image_url: eventObj.image_url,
                customer_provided_gear: eventObj.customer_provided_gear,
                instr_provided_gear: eventObj.instr_provided_gear,
                optional_gear: eventObj.optional_gear,
                dives: eventObj.dives,
                faq: eventObj.faq,
                home_carousel: eventObj.home_carousel,
                allow_multiple_discounts: eventObj.allow_multiple_discounts,
                global_max_people: eventObj.global_max_people
            });
            setFormStatus(null);
        }

        fileInput.current.value = '';
        setNewPhotoPending(false);
    };

    const filteredEvents =
        query === ''
            ? eventTemplates
            : eventTemplates.filter((et) => {
                return et.instr_title
                    ? et.instr_title.toLowerCase().includes(query.toLowerCase())
                    : false;
            })

    const handlePhotoChange = async (event) => {
        console.log('handlePhotoChange');
        setIsPhotoUploading(true);
        const file = event.target.files[0];
        const image_data = await convertToBase64(file);
        if (image_data) {
            setFormData({
                ...formData,
                image_data: `data:${file.type};base64,${image_data}`,
                image_url: `event_template_id_${formData.event_template_id}.jpeg`
            });
            setNewPhotoPending(true);
        }
        setIsPhotoUploading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let errors = validate(formData);

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setFormStatus({error: 'Some information was missing or invalid.  Please check again.'});
        } else {
            setFormErrors({})
            const apiName = 'InstrOfScubaAPI'
            const path = '/save-event-template'
            const options = {
                method: 'POST',
                body: {formData, instr_id},
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            try {
                await post({apiName, path, options}).response;
                dispatch(fetchEventTemplates({instr_id}));
                onExitManageEventTemplates();
            } catch (error) {
                setErrorInfo('handleSubmit - /save-event-template\n' + error);
            }
        }

        setIsSubmitting(false);
    }

    function validate(formData) {
        let errors = {};
        if (!formData.instr_title.trim()) {
            errors.instr_title = 'Admin Title is required';
            // TODO: Check if it already exists but trying to add New Event
        }

        if (!formData.customer_title.trim()) {
            errors.customer_title = 'Customer Title is required';
        }

        if (!formData.description) {
            errors.description = 'Description is required';
        }

        if (!formData.customer_provided_gear) {
            errors.customer_provided_gear = 'Customer provided gear is required';
        }

        if (!formData.instr_provided_gear) {
            errors.instr_provided_gear = 'Instructor provided gear is required';
        }

        if (!formData.dives) {
            errors.dives = 'Dives cannot be empty';
        }

        if (!formData.faq) {
            errors.faq = 'FAQs cannot be empty';
        }

        if (!formData.participation_criteria) {
            errors.participation_criteria = 'At least one customer type is required';
        }

        if (!formData.start_time) {
            errors.start_time = 'Start time is required'
        }

        if (!formData.end_time) {
            errors.end_time = 'End time is required'
        }

        if (formData.participation_criteria.length > 0) {
            // console.log(formData);
            formData.participation_criteria.forEach((criteria, index) => {
                if (!criteria.customer_type) {
                    errors[`customer_type_${index}`] = 'Customer Type is required';
                }

                const price = parseFloat(criteria.price === null || criteria.price === '' ? 0 : criteria.price);
                if (Number.isNaN(price) || price < 0) {
                    errors[`price_${index}`] = 'Price is required and must be a non-negative number.';
                }

                const max_people = parseInt(criteria.max_people);
                if (typeof max_people !== 'number' || Number.isNaN(max_people) || max_people < 1) {
                    errors[`max_people_${index}`] = 'Max People must not be zero';
                }

                const min_people = parseInt(criteria.min_people === null || criteria.min_people === '' ? 0 : criteria.min_people);
                if (Number.isNaN(min_people) || min_people < 0 || min_people > max_people) {
                    errors[`min_people_${index}`] = 'Min People must be greater than 0 and no more than Max People';
                }

                const gear_factor = parseFloat(criteria.gear_factor);
                if (typeof gear_factor !== 'number' || Number.isNaN(gear_factor) || gear_factor < 1) {
                    errors[`gear_factor_${index}`] = 'Gear Factor must be 1 or greater';
                }
            });
        }

        return errors;
    }

    useEffect(() => {
        if (sliceError) {
            setErrorInfo(`fetchEventTemplates - /get-event-templates\n${sliceError}`);
        }
    }, [sliceError]);

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Manage Event Templates
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Event Templates are used to save time when creating new events. They specify the title shown
                        to the customer, your private title to keep track of similar events you want to group together
                        under a single customer title, the participation criteria, and all the information displayed
                        on the details page of an event, such as the event description, gear requirements, FAQs, etc.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2 sm:col-start-1">
                            <Combobox as="div" value={selectedEvent} onChange={handleEventComboChange}>
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                    Existing Template to Modify
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(event) => setQuery(event.target.value)}
                                        displayValue={(et) => et}
                                    />
                                    <Combobox.Button
                                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>

                                    {filteredEvents.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredEvents.map((et) => (
                                                <Combobox.Option
                                                    key={et.event_template_id}
                                                    value={et.instr_title}
                                                    className={({focus}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({focus, selected}) => (
                                                        <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{et.instr_title}</span>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        focus ? 'text-white' : 'text-indigo-600'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="instr_title"
                                   className={`block text-sm font-medium leading-6 ${formErrors.instr_title ? 'text-red-900' : 'text-gray-900'}`}>
                                Admin Title
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="instr_title"
                                    id="instr_title"
                                    value={formData.instr_title}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.instr_title ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                This title is for your use only. It will not be shown to customers.
                            </p>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="customer_title"
                                   className={`block text-sm font-medium leading-6 ${formErrors.customer_title ? 'text-red-900' : 'text-gray-900'}`}>
                                Customer Title
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="customer_title"
                                    id="customer_title"
                                    value={formData.customer_title}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.customer_title ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Customers will see this one with the location title appended to the end.
                            </p>
                        </div>

                        <div className="col-span-full sm:col-span-2">
                            <label htmlFor="photo"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Event Photo
                            </label>
                            <div className="mt-2">
                                <div className="w-60">
                                    {isPhotoUploading ? (
                                        <BounceLoader color={"#3b82f6"} size={240}/>
                                    ) : (
                                        <img alt="event photo"
                                             src={eventImage}
                                             onError={(e) => {
                                                 e.target.onerror = null;
                                                 e.target.src = "/images/generic-event.webp"
                                             }} className="h-60 text-gray-300"
                                             aria-hidden="true"/>
                                    )}
                                    <button
                                        id="photo"
                                        type="button"
                                        className="rounded-md bg-white w-full px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-2"
                                        onClick={uploadPhoto}
                                    >
                                        Change
                                    </button>
                                </div>
                                <input type="file" ref={fileInput} onChange={handlePhotoChange}
                                       className="hidden" accept="image/*"/>
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="description"
                                   className={`block text-sm font-medium leading-6 ${formErrors.description ? 'text-red-900' : 'text-gray-900'}`}>
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="description"
                                    value={Array.isArray(formData.description) ? formData.description.join('\n\n') : formData.description || ''}
                                    onChange={handleChange}
                                    id="description"
                                    rows="12"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.description ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                This is the first introduction to the event on the details page. It should be a brief
                                overview of the event. Events will be paired with locations which will have their own
                                descriptions specific to directions and specific to the location itself.
                            </p>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="customer_provided_gear"
                                   className={`block text-sm font-medium leading-6 ${formErrors.customer_provided_gear ? 'text-red-900' : 'text-gray-900'}`}>
                                Customer Provided Gear
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="customer_provided_gear"
                                    id="customer_provided_gear"
                                    value={formData.customer_provided_gear}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.customer_provided_gear ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Type a comma-separated list and it will display bulletized for the customer.
                            </p>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="instr_provided_gear"
                                   className={`block text-sm font-medium leading-6 ${formErrors.instr_provided_gear ? 'text-red-900' : 'text-gray-900'}`}>
                                Instructor Provided Gear
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="instr_provided_gear"
                                    id="instr_provided_gear"
                                    value={formData.instr_provided_gear}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.instr_provided_gear ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Type a comma-separated list and it will display bulletized for the customer.
                            </p>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="optional_gear"
                                   className={`block text-sm font-medium leading-6 ${formErrors.optional_gear ? 'text-red-900' : 'text-gray-900'}`}>
                                Optional Gear
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="optional_gear"
                                    id="optional_gear"
                                    value={formData.optional_gear}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.optional_gear ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Type a comma-separated list and it will display bulletized for the customer.
                            </p>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="dives"
                                   className={`block text-sm font-medium leading-6 ${formErrors.dives ? 'text-red-900' : 'text-gray-900'}`}>
                                Dives
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="dives"
                                    value={formData.dives}
                                    onChange={handleChange}
                                    id="dives"
                                    rows="5"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.dives ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Describe the dives that will be done on this event.
                            </p>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="faq"
                                   className={`block text-sm font-medium leading-6 ${formErrors.faq ? 'text-red-900' : 'text-gray-900'}`}>
                                Frequently Asked Questions (FAQ)
                            </label>
                            <div className="mt-2">
                                <textarea
                                    name="faq"
                                    value={Array.isArray(formData.faq) ? formData.faq.join('\n') : formData.faq || ''}
                                    onChange={handleChange}
                                    id="faq"
                                    rows="5"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.faq ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Provide answers to the most common questions customers ask about this event.
                            </p>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="start_time"
                                   className={`block text-sm font-medium leading-6 ${formErrors.start_time ? 'text-red-900' : 'text-gray-900'}`}>
                                Start Time ({timeZone})
                            </label>
                            <div className="mt-2">
                                <input
                                    type="time"
                                    name="start_time"
                                    id="start_time"
                                    value={formData.start_time}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.start_time ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="end_time"
                                   className={`block text-sm font-medium leading-6 ${formErrors.start_time ? 'text-red-900' : 'text-gray-900'}`}>
                                End Time ({timeZone})
                            </label>
                            <div className="mt-2">
                                <input
                                    type="time"
                                    name="end_time"
                                    id="end_time"
                                    value={formData.end_time}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.start_time ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="global_max_people"
                                   className={`block text-sm font-medium leading-6 ${formErrors.global_max_people ? 'text-red-900' : 'text-gray-900'}`}>
                                Maximum Participants (weighted)
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="global_max_people"
                                    value={formData.global_max_people}
                                    onChange={handleChange}
                                    id="global_max_people"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.global_max_people ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <fieldset>
                                <div
                                    className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                                    <div key='home_carousel' className="relative flex items-start py-4">
                                        <div className="min-w-0 flex-1 text-sm leading-6">
                                            <label htmlFor='home_carousel'
                                                   className="font-medium text-gray-900">
                                                Display on home page?
                                            </label>
                                        </div>
                                        <div className="ml-3 flex h-6 items-center">
                                            <input
                                                id='home_carousel'
                                                name='home_carousel'
                                                type="checkbox"
                                                checked={formData.home_carousel}
                                                onChange={handleCheckedChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                        </div>
                                    </div>
                                    <div key='allow_multiple_discounts' className="relative flex items-start py-4">
                                        <div className="min-w-0 flex-1 text-sm leading-6">
                                            <label htmlFor='allow_multiple_discounts'
                                                   className="font-medium text-gray-900">
                                                Allow Multiple Discounts
                                            </label>
                                        </div>
                                        <div className="ml-3 flex h-6 items-center">
                                            <input
                                                id='allow_multiple_discounts'
                                                name='allow_multiple_discounts'
                                                type="checkbox"
                                                checked={formData.allow_multiple_discounts}
                                                onChange={handleCheckedChange}
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="sm:col-span-full">
                            <ParticipationCriteriaForm
                                formData={formData}
                                setFormData={setFormData}
                                formErrors={formErrors}
                                setFormStatus={setFormStatus}
                            />
                        </div>

                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${(formStatus && (formStatus.error)) || isSubmitting ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                <MdSave className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                            <button
                                type="button"
                                onClick={onExitManageEventTemplates}
                                className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                                <MdCancel className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Cancel
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            {
                formStatus && (formStatus.error ?
                        <div
                            className="w-full rounded-md bg-red-200 text-red-500 text-center font-bold py-2 mt-2">{formStatus.error}</div>
                        :
                        <div
                            className="w-full rounded-md bg-green-200 text-green-500 text-center font-bold py-2 mt-2">{formStatus.success}</div>

                )
            }
        </form>
    );
}

export default ManageEventTemplates;
