import React, {useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {post, get} from 'aws-amplify/api';
import {RadioGroup} from "@headlessui/react";
import SizesRadioGroup from "../components/SizesRadioGroup";
import {clearCourseSignupInfo} from "../redux/courseSignupInfoSlice";
import {convertToBase64, debounce, myCustomAuthTheme} from "./sharedHooks";
import {BounceLoader} from "react-spinners";
import {addCourseRegistration, checkUser, fetchUserDetails} from "../redux/authSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {toZonedTime, format} from 'date-fns-tz';
import {getImageUrl} from "../utils/sanitizeFunctions";
import withAuth from "./withAuth";
import {fetchOutstandingData} from "../redux/outstandingDataSlice";

const StudentProfile = (isCourseSignup) => {
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [errorInfo, setErrorInfo] = useState(null);
    const fileInput = useRef(null);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const loginEmail = useSelector((state) => state.auth.loginEmail);
    const instrOrgMemNum = useSelector((state) => state.instrDetails?.instrDetails.organization + '-' + state.instrDetails?.instrDetails.member_number);
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.auth.userDetails);
    const studentId = userDetails?.id || null;
    const timezone = useSelector(state => state.instrDetails?.instrDetails?.timezone);
    const [instrImage, setInstrImage] = useState('/images/portraits/unknown_male.png');
    const [userImage, setUserImage] = useState('/images/portraits/unknown_male.png');

    const uploadPhoto = () => {
        fileInput.current.click();
    }

    if (errorInfo) {
        throw errorInfo;
    }

    useEffect(() => {
        if (studentId) {
            setUserImage(getImageUrl('userPortrait-' + studentId + '.jpeg'));
        }
    }, [dispatch, studentId]);

    useEffect(() => {
        if (instrOrgMemNum) {
            setInstrImage(getImageUrl(instrOrgMemNum + '.jpeg'));
        }
    }, [instrOrgMemNum, dispatch]);

    const handlePhotoChange = async (event) => {
        setIsPhotoUploading(true);
        const file = event.target.files[0];
        const image_data = await convertToBase64(file);
        if (image_data === null) {
            setIsPhotoUploading(false);
            return;
        }

        const apiName = 'InstrOfScubaAPI';
        const path = '/upload-photo';
        const options = {
            method: 'POST',
            body: {
                image_type: 'student_portrait',
                image: image_data
            },
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            if (data.updated === true) {
                setUserImage(getImageUrl('userPortrait-' + studentId + '.jpeg'));
            } else {
                console.log(data.message);
            }
        } catch (error) {
            setErrorInfo('/upload-photo\n' + error);
        } finally {
            setIsPhotoUploading(false);
        }
    }

    const sizes_bcd = [
        {
            size: 'XS',
            heightImperial: "5' - 5'5\"",
            heightMetric: "152 - 165 cm",
            weightImperial: "100 - 125 lbs",
            weightMetric: "45 - 56 kg",
            waistImperial: "30 - 42\" ",
            waistMetric: "76 - 107cm"
        },
        {
            size: 'S',
            heightImperial: "5'2\" - 5'7\"",
            heightMetric: "157 - 170 cm",
            weightImperial: "120 - 155 lbs",
            weightMetric: "54 - 70 kg",
            waistImperial: "30 - 42\" ",
            waistMetric: "76 - 107 cm"
        },
        {
            size: 'M',
            heightImperial: "5'7\" - 5'10\"",
            heightMetric: "170 - 177 cm",
            weightImperial: "150 - 165 lbs",
            weightMetric: "68 - 74 kg",
            waistImperial: "33 - 45\" ",
            waistMetric: "84 - 114 cm"
        },
        {
            size: 'ML',
            heightImperial: "5'10\" - 6'0\"",
            heightMetric: "177 - 182 cm",
            weightImperial: "160 - 190 lbs",
            weightMetric: "72 - 86 kg",
            waistImperial: "35 - 47\" ",
            waistMetric: "89 - 119 cm"
        },
        {
            size: 'L',
            heightImperial: "6' - 6'2\"",
            heightMetric: "182 - 187 cm",
            weightImperial: "180 - 210 lbs",
            weightMetric: "81 - 95 kg",
            waistImperial: "38 - 50\" ",
            waistMetric: "97 - 127 cm"
        },
        {
            size: 'XL',
            heightImperial: "6'2\" - 6'5\"",
            heightMetric: "187 - 195 cm",
            weightImperial: "195 - 240 lbs",
            weightMetric: "88 - 108 kg",
            waistImperial: "41 - 53\" ",
            waistMetric: "104 - 135 cm"
        },
        {
            size: 'XXL',
            heightImperial: "6'2\" - 6'5\"",
            heightMetric: "187 - 195 cm",
            weightImperial: "230 - 270 lbs",
            weightMetric: "104 - 122 kg",
            waistImperial: "44 - 56\" ",
            waistMetric: "112 - 142 cm"
        }
    ]

    const sizes_wetsuit_male = [
        {
            size: 'XS',
            heightImperial: "5'5\" - 5'7\"",
            heightMetric: "165 - 170 cm",
            weightImperial: "125 - 135 lbs",
            weightMetric: "57 - 61 kg",
        },
        {
            size: 'S',
            heightImperial: "5'7\" - 5'9\"",
            heightMetric: "170 - 175 cm",
            weightImperial: "135 - 150 lbs",
            weightMetric: "61 - 68 kg",
        },
        {
            size: 'M',
            heightImperial: "5'9\" - 5'11\"",
            heightMetric: "175 - 181 cm",
            weightImperial: "150 - 170 lbs",
            weightMetric: "68 - 77 kg",
        },
        {
            size: 'ML',
            heightImperial: "5'9.5\" - 5'10.5\"",
            heightMetric: "177 - 179 cm",
            weightImperial: "155 - 175 lbs",
            weightMetric: "71 - 80 kg",
        },
        {
            size: 'L',
            heightImperial: "5'10\" - 6'0\"",
            heightMetric: "178 - 183 cm",
            weightImperial: "170 - 190 lbs",
            weightMetric: "77 - 86 kg",
        },
        {
            size: 'XL',
            heightImperial: "5'11\" - 6'1\"",
            heightMetric: "181 - 186 cm",
            weightImperial: "190 - 210 lbs",
            weightMetric: "86 - 95 kg",
        },
        {
            size: 'XXL',
            heightImperial: "6'0\" - 6'2\"",
            heightMetric: "183 - 188 cm",
            weightImperial: "210 - 230 lbs",
            weightMetric: "95 - 105 kg",
        },
        {
            size: '3XL',
            heightImperial: "6'1\" - 6'3\"",
            heightMetric: "186 - 191 cm",
            weightImperial: "230 - 250 lbs",
            weightMetric: "105 - 114 kg",
        }
    ]

    const sizes_wetsuit_female = [
        {
            size: '4',
            heightImperial: "5'4\" - 5'6\"",
            heightMetric: "163 - 168 cm",
            weightImperial: "110 lbs",
            weightMetric: "50 kg",
        },
        {
            size: '6',
            heightImperial: "5'5\" - 5'7\"",
            heightMetric: "165 - 170 cm",
            weightImperial: "115 lbs",
            weightMetric: "52 kg",
        },
        {
            size: '8',
            heightImperial: "5'6\" - 5'8\"",
            heightMetric: "168 - 173 cm",
            weightImperial: "120 lbs",
            weightMetric: "54.5 kg",
        },
        {
            size: '10',
            heightImperial: "5'7\" - 5'9\"",
            heightMetric: "170 - 175 cm",
            weightImperial: "130 lbs",
            weightMetric: "59 kg",
        },
        {
            size: '12',
            heightImperial: "5'8\" - 5'10\"",
            heightMetric: "173 - 178 cm",
            weightImperial: "140 lbs",
            weightMetric: "63.5 kg",
        },
        {
            size: '14',
            heightImperial: "5'9\" - 5'11\"",
            heightMetric: "175 - 181 cm",
            weightImperial: "150 lbs",
            weightMetric: "68 kg",
        }
    ]

    const handleBcdChange = (value) => {
        setFormData({...formData, bcd_size: value});
    }

    const handleWetsuitChange = (value) => {
        setFormData({...formData, wetsuit_size: value});
    }

    useEffect(() => {
        const updatedFormData = Object.keys(INITIAL_FORM_DATA).reduce((acc, key) => {
            // Use values from userDetails if available, otherwise fall back to INITIAL_FORM_DATA
            const newValue = userDetails && userDetails.hasOwnProperty(key) ? userDetails[key] : INITIAL_FORM_DATA[key];
            return {...acc, [key]: newValue};
        }, {});

        if (userDetails) {
            setPhoneInput(prevState => ({
                ...prevState,
                rawInput: userDetails.phone.replace(/^\+/, '')
            }));
        }

        setFormData(updatedFormData);
    }, [userDetails]);

    const gear = [
        {id: 1, stateName: 'owns_fins', name: 'Scuba Fins (do not include short snorkeling or long free diving fins)'},
        {id: 2, stateName: 'owns_boots', name: 'Dive Boots (hard soled)'},
        {id: 3, stateName: 'owns_wetsuit', name: 'Wet Suit'},
        {id: 4, stateName: 'owns_mask', name: 'Scuba/Snorkeling Mask'},
        {id: 5, stateName: 'owns_snorkel', name: 'Snorkel'},
        {id: 6, stateName: 'owns_bcd', name: 'Buoyancy Control Device (BCD)'},
        {id: 7, stateName: 'owns_regulator', name: 'Regulator Set (1st Stage, Primary + Alternate 2nd Stage, SPG)'},
        {id: 8, stateName: 'owns_dive_computer', name: 'Dive Computer'},
        {id: 9, stateName: 'owns_weights', name: 'Weights'},
        {id: 10, stateName: 'owns_tank', name: 'Tanks'},
    ];

    const paymentOptions = [
        {
            name: 'Visa, MasterCard, AMEX, Discover',
            supported: true,
            imageUrl: "/images/logos/visa-mastercard-amex-discover-icon.png",
            prefix: '', placeholder: 'Invoice will be emailed'
        },
        {
            name: 'Venmo',
            supported: true,
            imageUrl: "/images/logos/venmo_logo.png",
            prefix: '@',
            placeholder: 'Username'
        },
        {
            name: 'Zelle',
            supported: true,
            imageUrl: "/images/logos/zelle-logo.svg",
            prefix: '',
            placeholder: 'Email or Phone'
        },
        {
            name: 'Apple Cash',
            supported: true,
            imageUrl: "/images/logos/Apple-Cash.webp",
            prefix: '',
            placeholder: 'iPhone Mobile Number'
        },
        {
            name: 'Cash App',
            supported: true,
            imageUrl: "/images/logos/RGB_CashApp_Logo_Stacked_Black.png",
            prefix: '$',
            placeholder: 'CashTag'
        },
        {name: 'Cash', supported: true, imageUrl: "/images/logos/cash-logo.png", prefix: '$', placeholder: 'N/A'},
    ];

    // const [pmt, setPmt] = useState(paymentOptions[1].name);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const instrName = useSelector(state => state.instrDetails.instrDetails.first_name + ' ' + state.instrDetails.instrDetails.last_name);
    const instrTitle = useSelector(state => state.instrDetails.instrDetails.organization + ' ' + state.instrDetails.instrDetails.pro_level);

    const INITIAL_FORM_DATA = {
        first_name: userDetails?.first_name || '',
        middle_init: userDetails?.middle_init || '',
        last_name: userDetails?.last_name || '',
        preferred_email: loginEmail || '',
        phone: userDetails?.phone || '',
        date_of_birth: userDetails?.date_of_birth || '',
        sex: userDetails?.sex || 'male',
        street_address: userDetails?.street_address || '',
        city: userDetails?.city || '',
        state: userDetails?.state || '',
        zip: userDetails?.zip || '',
        country: userDetails?.country || '',
        bcd_size: userDetails?.bcd_size || '',
        wetsuit_size: userDetails?.wetsuit_size || '',
        shoe_size: userDetails?.shoe_size || '',
        owns_fins: userDetails?.owns_fins || false,
        owns_boots: userDetails?.owns_boots || false,
        owns_wetsuit: userDetails?.owns_wetsuit || false,
        owns_mask: userDetails?.owns_mask || false,
        owns_snorkel: userDetails?.owns_snorkel || false,
        owns_bcd: userDetails?.owns_bcd || false,
        owns_regulator: userDetails?.owns_regulator || false,
        owns_tank: userDetails?.owns_tank || false,
        owns_dive_computer: userDetails?.owns_dive_computer || false,
        owns_weights: userDetails?.owns_weights || false,
        preferred_payment: userDetails?.preferred_payment || 'Venmo',
        preferred_payment_username: userDetails?.preferred_payment_username || ''
    }
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);

    const selectedPaymentMethod = paymentOptions.find((option) => option.name === formData?.preferred_payment);

    const handlePaymentMethodChange = (value) => {
        // setPmt(value);
        // setFormData({...formData, 'preferred_payment': value});
        setFormData(prevFormData => ({...prevFormData, 'preferred_payment': value}));
    };

    const handleChange = (e) => {
        const {id, value, name} = e.target;
        if (id === 'phone') {
            setPhoneInput(prevState => ({
                ...prevState,
                rawInput: value
            }));
        } else {
            // setFormData({...formData, [name]: value});
            setFormData(prevFormData => ({...prevFormData, [name]: value}));
        }
    }

    const handleCheckedChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.checked});
    };

    const location = useLocation();
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});
    const [formStatus, setFormStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        const apiName = 'InstrOfScubaAPI'
        const path = '/savestudentinfo'
        const options = {
            method: 'POST',
            body: {formData: formData, phone: phoneInput},
            headers: {
                'Content-Type': 'application/json',
            }
        };

        let data;
        try {
            const {body} = await post({apiName, path, options}).response;
            await body.json();
            dispatch(checkUser({}));
        } catch (error) {
            setErrorInfo('handleSubmit - /savestudentinfo\n' + error);
        }

        setIsSubmitting(false);
        let errors = validate(formData);

        if (data?.messages) {
            data.messages.forEach(message => {
                if (message.error === 'duplicate_instr_email') {
                    errors.preferred_email = 'Email is already in use by an instructor.';
                } else if (message.error === 'duplicate_student_email') {
                    errors.preferred_email = 'Email is already in use by another student.';
                }
            });
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setFormStatus({error: 'Some information was missing or invalid.  Please check again.'})
            // intentionally not returning before post as we DO want to save the (good) data even if there are errors
        } else {
            setFormStatus({success: 'Your information has been saved.'})

            const redirectPath = location.state?.redirectPath;
            const autoRsvp = location.state?.autoRsvp;
            if (redirectPath) {
                setTimeout(() => {
                    navigate(redirectPath, {state: {autoRsvp}});
                }, 2000);
            }
        }
    };

    useEffect(() => {
        if (loginEmail) {
            dispatch(fetchUserDetails({}));
        }
    }, [dispatch, loginEmail]);

    const emailRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const phoneRef = useRef(null);
    const dobRef = useRef(null);

    useEffect(() => {
        setFormStatus(null);
    }, [formData]);

    function validate(formData) {
        let errors = {};
        if (!formData.first_name.trim()) {
            errors.first_name = 'First name is required';
        }

        if (!formData.last_name.trim()) {
            errors.last_name = 'Last name is required';
        }

        if (!formData.date_of_birth) {
            errors.date_of_birth = 'Date of Birth is required';
        }

        if (!formData.preferred_email) {
            errors.preferred_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.preferred_email)) {
            errors.preferred_email = 'Email address is invalid';
        }

        return errors;
    }

    const instrEmail = useSelector((state) => state.instrDetails?.instrDetails.preferred_email);
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails.instr_id);
    const visitedCourses = useSelector((state) => state.visitedCourses);
    const courseSignupInfo = useSelector((state) => state.courseSignupInfo);
    const createRegistration = async () => {
        const nowUtc = new Date();
        const zonedTime = toZonedTime(nowUtc, timezone);
        const registrationDate = format(zonedTime, 'yyyy-MM-dd', {timeZone: timezone});

        const formData = {
            instr_email: instrEmail,
            instr_id: instr_id,
            course_code: courseSignupInfo.course_code,
            course_name: courseSignupInfo.course_name,
            user_login_email: loginEmail,
            visited_courses: visitedCourses
        }

        const apiName = 'InstrOfScubaAPI'
        const path = '/course-signup'
        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            await post({apiName, path, options});

            dispatch(addCourseRegistration({
                ...formData,
                registration_date: registrationDate,
            }));
        } catch (error) {
            setErrorInfo('StudentProfile-createRegistration\n' + error);
        }
    }

    useEffect(() => {
        if (isCourseSignup && instrEmail && instr_id && studentId && courseSignupInfo.course_code && loginEmail) {
            createRegistration().then(() => {
                dispatch(clearCourseSignupInfo());
                dispatch(fetchOutstandingData(instr_id));
            });
        }
    }, [isCourseSignup, instrEmail, instr_id, studentId, courseSignupInfo.course_code, loginEmail, dispatch]);

    const [phoneInput, setPhoneInput] = useState({
        rawInput: '',
        isPossible: false,
        isValid: false,
        internationalFormat: '',
        nationalFormat: ''
    })

    useEffect(() => {
        const validatePhone = debounce(async () => {
            const apiName = 'InstrOfScubaAPI'
            const path = '/parse-phone'
            const options = {
                method: 'GET',
                queryParams: {phone: phoneInput.rawInput},
                headers: {'Content-Type': 'application/json'}
            };

            try {
                const {body} = await get({apiName, path, options}).response;
                const data = await body.json();
                const isPossible = data.isPossible;
                const isValid = data.isValid;
                const internationalFormat = data.internationalFormat;
                const nationalFormat = data.nationalFormat;

                if (isPossible && isValid) {
                    setPhoneInput(prevState => ({
                        ...prevState,
                        isPossible,
                        isValid,
                        internationalFormat,
                        nationalFormat
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phone: internationalFormat,
                        phone_display: nationalFormat
                    }));
                } else {
                    setPhoneInput(prevState => ({
                        ...prevState,
                        isPossible,
                        isValid,
                        internationalFormat,
                        nationalFormat: 'Invalid Number'
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phone: '',
                        phone_display: 'Invalid Number'
                    }));
                }
            } catch (error) {
                setErrorInfo('StudentProfile-validatePhone\n' + error);
            }
        }, 300);

        // Shortest possible international number is 3 digit country code and 5 digit number.
        if (isAuthenticated && phoneInput.rawInput.length >= 8) {
            validatePhone();
        }
    }, [phoneInput.rawInput, dispatch]);

    return (
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
            <main>
                <form noValidate onSubmit={handleSubmit}>
                    <div className="space-y-12">
                        {isCourseSignup && courseSignupInfo.course_name && (
                            <section className="isolate overflow-hidden bg-white px-6 lg:px-8">
                                <div className="relative mx-auto max-w-2xl py-16 sm:py-16 lg:max-w-4xl">
                                    <div
                                        className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36"/>
                                    <div
                                        className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center"/>
                                    <figure
                                        className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
                                        <div
                                            className="relative col-span-2 lg:col-start-1 lg:row-start-2">
                                            <svg
                                                viewBox="0 0 162 128"
                                                fill="none"
                                                aria-hidden="true"
                                                className="absolute -top-12 left-0 -z-10 h-32 stroke-gray-900/10"
                                            >
                                                <path
                                                    id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                                                />
                                                <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                                     x={86}/>
                                            </svg>
                                            <blockquote
                                                className="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                                <p>
                                                    Thank you for diving in with me on this exciting
                                                    journey
                                                    and signing up for
                                                    the {courseSignupInfo.course_name} course!
                                                    I'm thrilled to embark on this underwater adventure
                                                    with
                                                    you and share the depths of my knowledge.
                                                    Together, we'll explore the vibrant and mesmerizing
                                                    marine world. Let's get ready to make some waves!
                                                </p>
                                            </blockquote>
                                        </div>
                                        <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
                                            <img
                                                className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                                                src={instrImage} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "/images/portraits/unknown_male.png"
                                            }}
                                                alt="Instructor Portrait"
                                            />
                                        </div>
                                        <figcaption className="text-base lg:col-start-1 lg:row-start-3">
                                            <div
                                                className="font-semibold text-gray-900">{instrName}</div>
                                            <div className="mt-1 text-gray-500">{instrTitle}</div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </section>
                        )}

                        <div className="border-b border-gray-900/10 pb-12">

                            <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                We greatly appreciate your time and effort in completing this form. Your
                                detailed responses are key to tailoring an exceptional experience
                                specifically
                                for you. As we strive to ensure the highest level of satisfaction, the
                                information you provide will help us understand and cater to your needs
                                better.
                                Thank you for your assistance in this process.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div className="col-span-full">
                                    <label htmlFor="photo"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Photo
                                    </label>
                                    <div className="mt-2">
                                        <div className="w-60">
                                            {isPhotoUploading ? (
                                                <BounceLoader color={"#3b82f6"} size={240}/>
                                            ) : (
                                                <img alt="user portrait"
                                                     src={userImage}
                                                     onError={(e) => {
                                                         e.target.onerror = null;
                                                         e.target.src = "/images/portraits/unknown_male.png"
                                                     }} className="h-60 text-gray-300"
                                                     aria-hidden="true"/>
                                            )}
                                            <button
                                                id="photo"
                                                type="button"
                                                className="rounded-md bg-white w-full px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-2"
                                                onClick={uploadPhoto}
                                            >
                                                Change
                                            </button>
                                        </div>
                                        <input type="file" ref={fileInput} onChange={handlePhotoChange}
                                               className="hidden" accept="image/*"/>
                                    </div>
                                    <div className="text-sm text-gray-600 mt-2">
                                        For your photo to be accepted on certification cards,
                                        it must be an unobstructed full-face image of you (i.e. no dark
                                        glasses, scuba masks, hats, etc.)
                                        Your profile image may be publicly viewable by other users.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Personal Information
                            </h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                This information is used to lookup existing (and process new) scuba
                                certifications. Please double-check for accuracy.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="first_name"
                                           className={`block text-sm font-medium leading-6 ${formErrors.first_name ? 'text-red-900' : 'text-gray-900'}`}>
                                        First Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="first_name"
                                            ref={firstNameRef}
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            id="first_name"
                                            autoComplete="given-name"
                                            className={`block w-full rounded-md border-0 py-1.5 ${formErrors.first_name ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                        />
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                        Your first name may be publicly viewable by others.
                                    </p>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="middle_init"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Middle Initial
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="middle_init"
                                            value={formData.middle_init}
                                            onChange={handleChange}
                                            id="middle_init"
                                            autoComplete="additional-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="last_name"
                                           className={`block text-sm font-medium leading-6 ${formErrors.last_name ? 'text-red-900' : 'text-gray-900'}`}>
                                        Last Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="last_name"
                                            ref={lastNameRef}
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            id="last_name"
                                            autoComplete="family-name"
                                            className={`block w-full rounded-md border-0 py-1.5 ${formErrors.last_name ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                        />
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                        Your last <u>initial</u> may be publicly viewable by others.
                                    </p>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="phone"
                                           className={`block text-sm font-medium leading-6 ${formErrors.phone ? 'text-red-900' : 'text-gray-900'}`}>
                                        Phone Number (with Country Code)
                                    </label>
                                    <div className="mt-2">
                                        <div
                                            className={`flex bg-white items-center rounded-md ring-1 ring-inset h-8 ${formErrors.phone ? 'ring-red-300 focus-within:ring-red-500' : 'ring-gray-300 focus-within:ring-indigo-600'}`}>
                                                            <span
                                                                className="pl-3 text-gray-500 sm:text-sm">+</span>
                                            <input
                                                id="phone"
                                                ref={phoneRef}
                                                name="phone"
                                                value={phoneInput.rawInput || ''}
                                                onChange={handleChange}
                                                type="tel"
                                                autoComplete="tel"
                                                className={`h-8 flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${formErrors.phone ? 'text-red-900 placeholder:text-red-400' : ''}`}
                                            />
                                        </div>
                                        <small
                                            className={`block mt-2 ${formErrors.phone ? 'text-red-900' : 'text-gray-600'}`}>{phoneInput.nationalFormat ? 'Local Format: ' + phoneInput.nationalFormat : 'Enter country code and number, we will do the formatting for you.'}
                                        </small>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email"
                                           className={`block text-sm font-medium leading-6 ${formErrors.preferred_email ? 'text-red-900' : 'text-gray-900'}`}>
                                        Email Address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            ref={emailRef}
                                            value={formData.preferred_email || ''}
                                            onChange={handleChange}
                                            type="email"
                                            autoComplete="email"
                                            aria-invalid={formErrors.preferred_email ? 'true' : 'false'}
                                            aria-describedby={formErrors.preferred_email ? 'email-error' : null}
                                            className={`block w-full rounded-md border-0 py-1.5 ${formErrors.preferred_email ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                        />
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                        Note: Changing this email address will not change the email you
                                        use to log in.
                                    </p>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street_address"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Street address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="street_address"
                                            value={formData.street_address}
                                            onChange={handleChange}
                                            id="street_address"
                                            autoComplete="street-address"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="city"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            id="city"
                                            autoComplete="address-level2"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="state"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        State / Province
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            id="state"
                                            autoComplete="address-level1"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="zip"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        ZIP / Postal code
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="zip"
                                            value={formData.zip}
                                            onChange={handleChange}
                                            id="zip"
                                            autoComplete="postal-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="country"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="country"
                                            name="country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                            <option></option>
                                            <option>United States</option>
                                            <option>Canada</option>
                                            <option>Mexico</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="date_of_birth"
                                           className={`block text-sm font-medium leading-6 ${formErrors.date_of_birth ? 'text-red-900' : 'text-gray-900'}`}>
                                        Date of Birth
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="date"
                                            name="date_of_birth"
                                            ref={dobRef}
                                            value={formData.date_of_birth}
                                            onChange={handleChange}
                                            id="date_of_birth"
                                            autoComplete="bday"
                                            className={`block w-full rounded-md border-0 py-1.5 ${formErrors.date_of_birth ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label className="text-base font-semibold text-gray-900">Sex</label>
                                    <fieldset className="mt-4">
                                        <legend className="sr-only">Sex</legend>
                                        <div
                                            className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                            <div className="flex items-center">
                                                <input
                                                    id='male'
                                                    name="sex"
                                                    value="male"
                                                    type="radio"
                                                    checked={formData.sex === 'male'}
                                                    onChange={handleChange}
                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                                <label htmlFor='male'
                                                       className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    Male
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    id='female'
                                                    name="sex"
                                                    value="female"
                                                    type="radio"
                                                    checked={formData.sex === 'female'}
                                                    onChange={handleChange}
                                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                                <label htmlFor='female'
                                                       className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                                    Female
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Buoyancy Compensator Device (BCD) Size
                            </h2>
                            <p className="mt-1 mb-3 text-sm leading-6 text-gray-600">
                                Help us ensure you get the right fit.
                            </p>

                            <div
                                className="p-4 grid md:grid-cols-4 md:pl-4 md:pr-6 bg-gray-100 md:text-center">
                                <div className="font-medium text-gray-500 md:text-left">Size</div>
                                <div className="font-medium text-gray-500">Height</div>
                                <div className="font-medium text-gray-500">Weight</div>
                                <div className="font-medium text-gray-500">Waist</div>
                            </div>
                            <SizesRadioGroup sizes={sizes_bcd} selected={formData.bcd_size}
                                             onSelectionChange={handleBcdChange}/>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-900 mt-3">
                                Wetsuit Size
                            </h2>
                            <p className="mt-1 mb-3 text-sm leading-6 text-gray-600">
                                Help us ensure you get the right fit.
                            </p>
                            <div
                                className="p-4 grid md:grid-cols-3 md:pl-4 md:pr-6 bg-gray-100 md:text-center">
                                <div className="font-medium text-gray-500 md:text-left">Size</div>
                                <div className="font-medium text-gray-500">Height</div>
                                <div className="font-medium text-gray-500">Weight</div>
                            </div>
                            <SizesRadioGroup
                                sizes={formData.sex === 'male' ? sizes_wetsuit_male : sizes_wetsuit_female}
                                selected={formData.wetsuit_size}
                                onSelectionChange={handleWetsuitChange}/>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="sm:col-span-2 sm:col-start-1 mt-3">
                                <label htmlFor="shoe_size"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Shoe Size
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="shoe_size"
                                        value={formData.shoe_size}
                                        onChange={handleChange}
                                        id="shoe_size"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <fieldset>
                                <legend
                                    className="text-base font-semibold leading-6 text-gray-900">What
                                    scuba gear, if any, do you already own (and have with you)?
                                </legend>
                                <div
                                    className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                                    {gear.map((item, itemIdx) => (
                                        <div key={itemIdx} className="relative flex items-start py-4">
                                            <div className="min-w-0 flex-1 text-sm leading-6">
                                                <label htmlFor={`gear-${item.id}`}
                                                       className="font-medium text-gray-900">
                                                    {item.name}
                                                </label>
                                            </div>
                                            <div className="ml-3 flex h-6 items-center">
                                                <input
                                                    id={`gear-${item.id}`}
                                                    name={item.stateName}
                                                    type="checkbox"
                                                    checked={formData[item.stateName]}
                                                    onChange={handleCheckedChange}
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </div>

                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-10 space-y-10">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-sm font-medium leading-6 text-gray-900">
                                        Preferred Payment Method</h2>
                                    <div
                                        className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                        Your instructor will send an invoice / payment request.
                                    </div>
                                </div>
                                <RadioGroup value={formData.preferred_payment} onChange={handlePaymentMethodChange}
                                            className="mt-2">
                                    <RadioGroup.Label className="sr-only">Choose a payment
                                        option</RadioGroup.Label>
                                    <div
                                        className="grid grid-cols-3 gap-3 sm:grid-cols-4 md:grid-cols-7">
                                        {paymentOptions.map((option) => (
                                            <RadioGroup.Option
                                                key={option.name}
                                                value={option.name}
                                                checked={formData.preferred_payment === option.name}
                                                className={({focus: isFocused, checked}) =>
                                                    classNames(
                                                        option.supported ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
                                                        isFocused ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                                                        checked
                                                            ? 'border-4 border-indigo-600' : 'border-4 border-transparent',
                                                        'relative flex items-center justify-center rounded-md text-sm font-semibold uppercase sm:flex-1 w-20 h-20',
                                                    )
                                                }
                                                disabled={!option.supported}
                                            >
                                                <RadioGroup.Label as="span"
                                                                  className="w-full h-full block p-1">
                                                    <img src={option.imageUrl} alt={option.name}
                                                         className="w-full h-full object-contain"/>
                                                </RadioGroup.Label>
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1 mt-3">
                                <label htmlFor="preferred_payment_username"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Payment Username/Code
                                </label>
                                <div className="mt-2 relative rounded-md border-0 shadow-sm">
                                    <div
                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm">
                                                        {selectedPaymentMethod.prefix}
                                                    </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="preferred_payment_username"
                                        value={formData.preferred_payment_username}
                                        onChange={handleChange}
                                        id="preferred_payment_username"
                                        placeholder={selectedPaymentMethod.placeholder}
                                        className="block w-full pl-7 pr-12 border-0 border-gray-300 rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="mt-6 flex justify-end gap-x-6">
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {isSubmitting ? 'Saving...' : 'Save'}
                        </button>
                    </div>
                    {formStatus && (formStatus.error ?
                            <div
                                className="w-full rounded-md bg-red-200 text-red-500 text-center font-bold py-2 mt-2">{formStatus.error}</div>
                            :
                            <div
                                className="w-full rounded-md bg-green-200 text-green-500 text-center font-bold py-2 mt-2">{formStatus.success}</div>

                    )}
                </form>
            </main>
        </div>
    );
}

export default withAuth(StudentProfile, {initialState: 'signUp', showStudentIntro: true});
