import React from 'react';
import { FaGraduationCap, FaChalkboardTeacher } from 'react-icons/fa'; // Importing the icons
import { Link } from 'react-router-dom';
import {useEffect} from "react";
import {fetchInstructors} from "../redux/instructorsSlice";
import {useDispatch, useSelector} from "react-redux";

const LandingPage = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.instructors?.status);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchInstructors());
        }
    }, [status, dispatch]);

    return (
        <div data-testid="landing-page" className="flex flex-col justify-center items-center min-h-[calc(100vh-48px)] bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 md:w-1/2">
                <h1 className="text-4xl mb-4 text-center">Are you a student or instructor?</h1>
                <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
                    <Link to="/instructor-directory" className="flex items-center justify-center w-full md:w-1/2 text-center py-4 px-6 rounded-lg bg-indigo-500 text-white hover:bg-indigo-600">
                        <FaGraduationCap className="mr-2"/> I am a Student
                    </Link>
                    <Link to="/prospective-instructor" className="flex items-center justify-center w-full md:w-1/2 text-center py-4 px-6 rounded-lg bg-green-500 text-white hover:bg-green-600">
                        <FaChalkboardTeacher className="mr-2"/> I am an Instructor
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
