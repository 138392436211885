import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {get, post} from "aws-amplify/api";

export const fetchInstrDetails = createAsyncThunk(
    'instrDetails/fetch',
    async (_, {getState, rejectWithValue}) => {
        try {
            const currentURL = getState().url.currentURL;
            const storedInstrDetails = JSON.parse(localStorage.getItem('instrDetails'));
            if (storedInstrDetails) {
                if (storedInstrDetails.domain_pk !== currentURL) {
                    localStorage.removeItem('instrDetails');
                } else {
                    return storedInstrDetails;
                }
            }

            if (currentURL) {
                const apiName = 'InstrOfScubaAPI'
                const path = '/domain-lookup'
                const options = {
                    method: 'GET',
                    queryParams: {
                        hostname: currentURL
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                };

                try {
                    const {body} = await get({apiName, path, options}).response;
                    const data = await body.json();
                    if (data.domain_exists === true) {
                        const proLevelInfo = data.pro_level_info || [];
                        const foremostInfo = proLevelInfo.find(info => info.foremost);

                        if (foremostInfo) {
                            data.organization = foremostInfo.pro_level_organization;
                            data.pro_level = foremostInfo.pro_level;
                            data.member_number = foremostInfo.member_number;
                        }

                        localStorage.setItem('instrDetails', JSON.stringify(data));
                        return data;
                    } else {
                        return data;
                    }
                } catch (error) {
                    console.log(error);
                    return rejectWithValue(error.message);
                }
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveInstrDetails = createAsyncThunk(
    'instrDetails/save',
    async ({ formData, credentials, courses, phoneInput }, { rejectWithValue }) => {
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-instr-info'
        const options = {
            method: 'POST',
            body: { formData, credentials, courses, phone: phoneInput },
            headers: {
                'Content-Type': 'application/json',
            }
        };

        try {
            const { body } = await post({ apiName, path, options }).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const instrDetailsSlice = createSlice({
    name: 'instrDetails',
    initialState: {
        instrDetails: {
            instr_id: '',
            domain_pk: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_display: '',
            facebook_link: '',
            instagram_link: '',
            x_link: '',
            youtube_link: '',
            whatsapp_link: '',
            linkedin_link: '',
            about_me: [],
            courses: [],
            pro_level_info: [],
            organization: '',
            pro_level: '',
            member_number: '',
            store_resort: '',
            domain_exists: null,
        },
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstrDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchInstrDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.instrDetails = action.payload;
                if (action.payload.domain_exists === true) {
                    const firstName = action.payload.first_name || '';
                    const lastName = action.payload.last_name || '';
                    document.title = `${firstName} ${lastName}`.trim();
                }
            })
            .addCase(fetchInstrDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // .addCase(saveInstrDetails.pending, (state) => {
            //     state.status = 'loading';
            // })
            .addCase(saveInstrDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(saveInstrDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const selectInstrDetailsStatus = (state) => state.instrDetails.status;
export default instrDetailsSlice.reducer;
