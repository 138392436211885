import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

export const fetchCustomerTypes = createAsyncThunk('customerTypes/fetchCustomerTypes', async (_, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-customer-types';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

const customerTypesSlice = createSlice({
    name: 'customerTypes',
    initialState: {
        customerTypes: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerTypes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomerTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customerTypes = action.payload;
            })
            .addCase(fetchCustomerTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default customerTypesSlice.reducer;
