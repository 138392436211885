import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

export const fetchLocations = createAsyncThunk('locations/fetchLocations', async ({instr_id}, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-locations';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            queryParams: {instr_id}
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

const locationSlice = createSlice({
    name: 'locations',
    initialState: {
        locations: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.locations = action.payload.map(location => {
                    try {
                        const transformPoint = (geoJSONColumn) => {
                            const parsed = JSON.parse(geoJSONColumn);
                            return `${parsed.coordinates[0]},${parsed.coordinates[1]}`;
                        };

                        return {
                            ...location,
                            parking_coordinates: transformPoint(location.parking_coordinates),
                            linkup_coordinates: transformPoint(location.linkup_coordinates),
                            activity_coordinates: transformPoint(location.activity_coordinates),
                        };
                    } catch (error) {
                        state.status = 'failed';
                        state.error = error.message;
                        return null;
                    }
                });
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default locationSlice.reducer;
