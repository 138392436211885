import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';

export const fetchInstructors = createAsyncThunk(
    'instructors/fetchInstructors',
    async () => {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-instructors';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    }
);

const initialState = {
    instructors: [],
    selectedInstructorId: null,
    desiredLocation: {
        lat: null,
        long: null,
        formattedAddress: '',
        rawInput: ''
    },
    courseFilter: '',
    status: 'idle',
    error: null,
};

const instructorsSlice = createSlice({
    name: 'instructors',
    initialState,
    reducers: {
        setSelectedInstructorId: (state, action) => {
            state.selectedInstructorId = action.payload;
        },
        setDesiredLocation: (state, action) => {
            state.desiredLocation = {
                ...state.desiredLocation,
                lat: action.payload.lat,
                long: action.payload.long,
                formattedAddress: action.payload.formattedAddress,
            };
        },
        setCourseFilter: (state, action) => {
            state.courseFilter = action.payload;
        },
        setRawInput: (state, action) => {
            state.desiredLocation.rawInput = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstructors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchInstructors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.instructors = action.payload;
            })
            .addCase(fetchInstructors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setRawInput, setCourseFilter, setSelectedInstructorId, setDesiredLocation } = instructorsSlice.actions;
export default instructorsSlice.reducer;