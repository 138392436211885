import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

export const fetchForms = createAsyncThunk('forms/fetchForms', async (_, {rejectWithValue, dispatch}) => {
    try {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-forms';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
    }
});

const formsSlice = createSlice({
    name: 'forms',
    initialState: {
        forms: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchForms.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchForms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.forms = action.payload;
            })
            .addCase(fetchForms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default formsSlice.reducer;
