import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fetchEventById} from "../redux/eventsSlice";
import EventDetails from './EventDetails';
import AfterEventDetails from './AfterEventDetails';
import {LoadingSpinner} from "./LoadingSpinner";
import {setLastPage} from "../redux/navSlice";
import {useParams} from "react-router-dom";
import Error404 from "./404Page";

const EventDetailsSelector = () => {
    const {eventId} = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.events.status === 'loading');
    const rsvpStatusChanged = useSelector(state => state.events.rsvpChanged);

    const eventDetails = useSelector(state => {
        const events = state.events.items;

        for (const year in events) {
            for (const month in events[year]) {
                for (const day in events[year][month]) {
                    const dayEvents = events[year][month][day];
                    const foundEvent = dayEvents.find(event => event.event_id === Number(eventId));
                    if (foundEvent) {
                        return foundEvent;
                    }
                }
            }
        }
        return null; // Return null if the event is not found
    });

    useEffect(() => {
        if (!eventDetails) {
            dispatch(fetchEventById({event_id: eventId}));
        }
    }, [eventId, eventDetails, dispatch]);

    useEffect(() => {
        if (!eventDetails || String(eventDetails.event_id) !== String(eventId)) {
            // console.log(eventDetails, eventId);
            dispatch(fetchEventById({event_id: eventId}));
        }
    }, [eventId, eventDetails, dispatch]);

    useEffect(() => {
        if (rsvpStatusChanged) {
            // console.log(rsvpStatusChanged, eventId);
            dispatch(fetchEventById({event_id: eventId}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rsvpStatusChanged, dispatch]);

    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    if (isLoading) {
        return <LoadingSpinner isLoading={true}/>;
    }

    if (!eventDetails) {
        return (
            <Error404/>
        )
    }

    const eventDate = new Date(eventDetails.end_time);
    const currentDate = new Date();
    const isEventCompleted = currentDate > eventDate;

    return isEventCompleted ? <AfterEventDetails eventId={eventId} eventDetails={eventDetails}/> :
        <EventDetails eventId={eventId} eventDetails={eventDetails}/>;
};

export default EventDetailsSelector;
