import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from "aws-amplify/api";

const calculateDiscountedTotal = (products) => {
    return products.reduce((acc, product) => acc + calculateDiscountedPrice(product), 0);
};

const calculateTaxes = (products) => {
    return products.reduce((acc, product) => acc + parseFloat(product.taxes || 0), 0);
};

const calculateShipping = (products) => {
    return products.reduce((acc, product) => acc + parseFloat(product.shipping || 0), 0);
};

const calculateSubtotal = (products) => {
    return products.reduce((acc, product) => acc + parseFloat(product.price), 0);
};

const calculateTotal = (discountedTotal, taxes, shipping) => {
    return discountedTotal + taxes + shipping;
};

const calculateTotalDiscountValue = (products) => {
    return products.reduce((acc, product) => {
        if (product && product.discount) {
            if (product.discount.type === 'percentage') {
                const rate = parseFloat(product.discount.value);
                if (!isNaN(rate)) {
                    return acc + (product.price * (rate / 100));
                }
            } else if (product.discount.type === 'fixed') {
                const amount = parseFloat(product.discount.value);
                if (!isNaN(amount)) {
                    return acc + amount;
                }
            }
        }
        return acc;
    }, 0);
};

const calculateDiscountedPrice = (product) => {
    let price = parseFloat(product.price);

    if (product.discount) {
        if (product.discount.type === 'percentage') {
            price = price - (price * (parseFloat(product.discount.value) / 100));
        } else if (product.discount.type === 'fixed') {
            price = price - parseFloat(product.discount.value);
        }
    }

    return price
};

export const fetchDiscountDetails = createAsyncThunk(
    'paymentGateway/fetchDiscountDetails',
    async ({instr_id, code, productIds}, {rejectWithValue}) => {
        try {
            const apiName = 'InstrOfScubaAPI'
            const path = '/validate-discount-code'
            const options = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                queryParams: { discount_code: code, instr_id: instr_id, product_ids: productIds }
            };

            const {body} = await get({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const paymentGatewaySlice = createSlice({
    name: 'paymentGateway',
    initialState: {
        instr_id: null,
        products: [],
        subtotal: 0,
        discountedTotal: 0,
        taxes: 0,
        shipping: 0,
        total: 0,
        totalDiscountValue: 0,
        discount_status: 'idle',
        error: null,
        resultModalObj: {
            title: '',
            type: '',
            body: '',
            show: false
        }
    },
    reducers: {
        addProductIfNotExists: (state, action) => {
            const newProduct = action.payload;
            const existingProductIndex = state.products.findIndex(product => product.id === newProduct.id);
            if (existingProductIndex === -1) {
                state.products.push(newProduct);
            }
            // Can only have one subscription, remove the old one if it exists
            if (newProduct.id.startsWith('subscriptions-')) {
                state.products = state.products.filter(product => !product.id.startsWith('subscriptions-'));
            }
            state.subtotal = calculateSubtotal(state.products);
            state.discountedTotal = calculateDiscountedTotal(state.products);
            state.taxes = calculateTaxes(state.products);
            state.shipping = calculateShipping(state.products);
            state.totalDiscountValue = calculateTotalDiscountValue(state.products);
            state.total = calculateTotal(state.discountedTotal, state.taxes, state.shipping);
        },
        removeProduct: (state, action) => {
            state.products = state.products.filter(product => product.id !== action.payload.id);
            state.subtotal = calculateSubtotal(state.products);
            state.discountedTotal = calculateDiscountedTotal(state.products);
            state.taxes = calculateTaxes(state.products);
            state.shipping = calculateShipping(state.products);
            state.totalDiscountValue = calculateTotalDiscountValue(state.products);
            state.total = calculateTotal(state.discountedTotal, state.taxes, state.shipping);
        },
        applyDiscounts: (state, action) => {
            const {type, discount_value, discount_type, product_id, code} = action.payload;

            if (type === 'success') {
                const product = state.products.find(p => p.id === product_id);
                if (product) {
                    const discount_value_float = parseFloat(discount_value);
                    product.discount = {
                        type: discount_type,
                        value: Math.round(discount_value_float * 100),
                        code: code
                    };
                }

                state.subtotal = calculateSubtotal(state.products);
                state.discountedTotal = calculateDiscountedTotal(state.products);
                state.taxes = calculateTaxes(state.products);
                state.shipping = calculateShipping(state.products);
                state.totalDiscountValue = calculateTotalDiscountValue(state.products);
                state.total = calculateTotal(state.discountedTotal, state.taxes, state.shipping);
            }
        },
        hideModal: (state) => {
            state.resultModalObj.show = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDiscountDetails.fulfilled, (state, action) => {
                // console.log(action.payload);
                state.status = 'succeeded';
                paymentGatewaySlice.caseReducers.applyDiscounts(state, action);
                const message = action.payload.message;
                if (message) {
                    state.resultModalObj = {
                        title: action.payload.title,
                        type: action.payload.type,
                        body: message,
                        show: true
                    };
                    state.showModal = true;
                } else {
                    state.resultModalObj = {
                        title: null,
                        type: null,
                        body: null,
                        show: false
                    };
                }
            })
            .addCase(fetchDiscountDetails.rejected, (state, action) => {
                state.discount_status = 'failed';
                state.error = action.payload;
            });
    }
});

export const {
    addProductIfNotExists,
    removeProduct,
    hideModal
} = paymentGatewaySlice.actions;

export default paymentGatewaySlice.reducer;