import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get} from 'aws-amplify/api';

export const fetchStudents = createAsyncThunk(
    'students/fetchStudents',
    async () => {
        const apiName = 'InstrOfScubaAPI';
        const path = '/get-student-list';
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const {body} = await get({apiName, path, options}).response;
        return await body.json();
    }
);

const initialState = {
    studentList: [],
    status: 'idle',
    error: null,
};

const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        clearStudentList: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStudents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.studentList = action.payload;
            })
            .addCase(fetchStudents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const {clearStudentList} = studentsSlice.actions;
export default studentsSlice.reducer;