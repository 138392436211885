import React, {useEffect, useRef, useState} from "react";
import {generateClient, get, post} from "aws-amplify/api";
import {useDispatch, useSelector} from "react-redux";
import {convertToBase64, debounce} from "./sharedHooks";
import ResultModal from "./ResultModal";
import {BounceLoader} from "react-spinners";
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from "react-icons/ai";
import {saveBioVersions} from "../redux/bioSlice";
import {BioPagination} from "../components/BioPagination";
import SocialMediaForm from "../components/SocialMediaForm";
import {LoadingSpinner} from "./LoadingSpinner";
import PaymentOptions from "../components/PaymentOptions";
import {useNavigate} from 'react-router-dom';
import {fetchAuthSession} from "aws-amplify/auth";
import * as subscriptions from "../graphql/subscriptions";
import {getImageUrl} from "../utils/sanitizeFunctions";
import {Amplify} from "aws-amplify";
import amplifyConfig from "../amplifyconfiguration.json";
import customConfig from "../customconfig.json";
import withAuth from "./withAuth";
import {saveInstrDetails} from "../redux/instrDetailsSlice";
import {updateImageTime} from "../redux/authSlice";

// import {ConsoleLogger} from 'aws-amplify/utils';
// ConsoleLogger.LOG_LEVEL = 'DEBUG';

Amplify.configure({...amplifyConfig}, {...customConfig});

const getJwtToken = async () => {
    try {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString();
    } catch (error) {
        console.error("Error fetching JWT token:", error);
        return null;
    }
};

const InstructorProfile = () => {
    const dispatch = useDispatch();
    const [errorInfo, setErrorInfo] = useState(null);
    const userLoginEmail = useSelector((state) => state.auth.loginEmail);
    const [isLoaded, setIsLoaded] = useState(false);
    const [credentials, setCredentials] = useState([{organization: '', memberNumber: '', proLevel: ''}]);
    const firstCredentialOrganization = credentials?.[0]?.organization || null;
    const firstCredentialProLevel = credentials?.[0]?.proLevel || null;
    const firstCredentialMemberNumber = credentials?.[0]?.memberNumber || null;
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [instrImageUrl, setInstrImageUrl] = useState('/images/portraits/unknown_male.png');
    const instrOrgMemNum = (firstCredentialOrganization && firstCredentialMemberNumber)
        ? firstCredentialOrganization + '-' + firstCredentialMemberNumber
        : null;
    const navigate = useNavigate();
    const hasPADIWithMemberNumber = credentials.some(credential => credential.organization === "PADI" && credential.memberNumber);
    const lastPage = useSelector((state) => state.navigation.lastPage) || '/';
    const [locationInput, setLocationInput] = useState({
        rawInput: '',
        formattedAddress: '',
        lat: '',
        long: ''
    });
    const [phoneInput, setPhoneInput] = useState({
        rawInput: '',
        isPossible: false,
        isValid: false,
        internationalFormat: '',
        nationalFormat: ''
    })
    const fileInput = useRef(null);
    const uploadPhoto = () => {
        fileInput.current.click();
    }
    const INITIAL_FORM_DATA = {
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '',
        employment_status: '',
        location: '',
        latitude: '',
        longitude: '',
        preferred_email: '',
        phone: '',
        phone_display: '',
        facebook_link: '',
        instagram_link: '',
        x_link: '',
        whatsapp_link: '',
        youtube_link: '',
        linkedin_link: '',
        about_me: '',
        store_resort: '',
        pmt_stripe_integration: false,
        pmt_venmo_username: '',
        pmt_zelle_username: '',
        pmt_apple_username: '',
        pmt_cash_app_username: '',
    }
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [showModal, setShowModal] = useState(false);
    const [resultModalObj, setResultModalObj] = useState({
        type: '',
        title: '',
        body: ''
    });
    const closeModal = () => {
        setShowModal(false);
    };
    const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const handlePhotoChange = async (event) => {
        setIsPhotoUploading(true);
        const file = event.target.files[0];
        const image_data = await convertToBase64(file);
        if (image_data === null) {
            setIsPhotoUploading(false);
            return;
        }

        const apiName = 'InstrOfScubaAPI';
        const path = '/upload-photo';
        const options = {
            method: 'POST',
            body: {
                image_type: 'instructor_portrait',
                organization: firstCredentialOrganization,
                member_number: firstCredentialMemberNumber,
                image: image_data
            },
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const {body} = await post({apiName, path, options}).response;
            const data = await body.json();
            if (data.updated === true) {
                setFormErrors(prevErrors => ({...prevErrors, member_number: null}));
                const baseImageUrl = getImageUrl(`${instrOrgMemNum}.jpeg`);
                const timestamp = new Date().getTime();
                setInstrImageUrl(`${baseImageUrl}?${timestamp}`);
                dispatch(updateImageTime());
            } else {
                setFormErrors(prevErrors => ({...prevErrors, member_number: data.message}));
                setResultModalObj({
                    type: 'failure',
                    title: 'Cannot Change Photo!',
                    body: data.message
                });
                setShowModal(true);
            }
        } catch (error) {
            setErrorInfo('InstructorProfile-handlePhotoChange\n' + error);
        } finally {
            setIsPhotoUploading(false);
        }
    }

    useEffect(() => {
        if (instrOrgMemNum) {
            setInstrImageUrl(getImageUrl(`${instrOrgMemNum}.jpeg`));
        }
    }, [instrOrgMemNum, dispatch])

    const handleChange = (e) => {
        const {id, value, name, type} = e.target;
        if (id === 'location') {
            setLocationInput(prevState => ({...prevState, rawInput: value}));
        } else if (id === 'phone') {
            setPhoneInput(prevState => ({...prevState, rawInput: value}));
        } else if (type === 'radio') {
            setFormData(prevState => ({...prevState, [name]: value}));
        } else {
            setFormData({...formData, [name]: value});
        }
    }

    const handleAboutMeChange = (e) => {
        const textareaContent = e.target.value;
        let updatedBioVersions = [...bioVersions];
        updatedBioVersions[currentVersionIndex] = textareaContent;
        setBioVersions(updatedBioVersions);
        setFormData(prevFormData => ({...prevFormData, about_me: textareaContent}));
    }

    const [formErrors, setFormErrors] = useState({});
    const [formStatus, setFormStatus] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let missingSubscription = false;

        setIsSubmitting(true);

        try {
            const data = await dispatch(saveInstrDetails({formData, credentials, courses, phoneInput})).unwrap();

            let errors = validate(formData, credentials);
            if (data?.messages) {
                data.messages.forEach(message => {
                    if (message.error === 'duplicate_mem_num') {
                        let memberNumberValue = message.value;
                        let index = credentials.findIndex(credential => credential.memberNumber === memberNumberValue);
                        if (index !== -1) {
                            if (!errors.credentials) {
                                errors.credentials = [];
                            }
                            if (!errors.credentials[index]) {
                                errors.credentials[index] = {};
                            }
                            errors.credentials[index].memberNumber = 'Member Number is already in use by another instructor';
                        }
                    } else if (message.error === 'duplicate_instr_email') {
                        errors.preferred_email = 'Email is already in use by another instructor.';
                    } else if (message.error === 'duplicate_student_email') {
                        errors.preferred_email = 'Email is already in use by a student.';
                    } else if (message.missing_subscription) {
                        missingSubscription = true;
                    }
                });
            }

            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                setFormStatus({error: 'Some information was missing or invalid.  Please check again.'})
            } else {
                setFormErrors({})
                setFormStatus({success: 'Your information has been saved.'})

                if (missingSubscription) {
                    navigate('/manage-subscription')
                } else {
                    navigate(lastPage)
                }
            }

        } catch (error) {
            setErrorInfo('handleSubmit - /save-instr-info\n' + error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // This is only slightly different from /domain-lookup, but does have some unique information we don't necessarily
    // want to share with students (who could inspect the redux store).  e.g. DOB, employment status,
    // and payment integration information.
    const fetchInstrProfile = async () => {
        try {
            const apiName = 'InstrOfScubaAPI';
            const path = '/get-instr-info';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const {body} = await get({apiName, path, options}).response;
            const data = await body.json();
            const preferredEmail = data.preferred_email;
            const finalEmail = preferredEmail ? preferredEmail : userLoginEmail;
            const userCourses = data.courses || [];
            setInstrCourses(userCourses);
            setFormData(prevFormData => ({
                ...prevFormData, ...data,
                about_me: Array.isArray(data.about_me) ? data.about_me.join('\n\n') : '',
                preferred_email: finalEmail
            }));

            const phoneWithoutPlus = data.phone && data.phone.startsWith('+') ? data.phone.slice(1) : data.phone;
            setPhoneInput(prevPhoneInput => ({...prevPhoneInput, rawInput: phoneWithoutPlus}));
            setLocationInput(prevLocationInput => ({...prevLocationInput, rawInput: data.location}));

            const transformedData = (Array.isArray(data.pro_level_info) ? data.pro_level_info : []).map(item => ({
                organization: item.pro_level_organization,
                proLevel: item.pro_level,
                memberNumber: item.member_number || ''
            }));

            if (transformedData.length === 0) {
                setCredentials([{organization: '', memberNumber: '', proLevel: ''}]);
            } else {
                setCredentials(transformedData);
            }

            setBioVersions(Array.isArray(data.about_me) && data.about_me.length ? [data.about_me.join('\n\n')] : '');
            setIsLoaded(true);
        } catch (error) {
            setErrorInfo('InstructorProfile-fetchInstrProfile\n' + error);
        }
    };

    if (errorInfo) {
        throw errorInfo;
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchInstrProfile();
        } else {
            setIsLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);  // ignore ESLint missing dependencies, it's good.

    const emailRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const phoneRef = useRef(null);
    const dobRef = useRef(null);
    const employmentStatusRef = useRef(null);

    useEffect(() => {
        setFormStatus(null);
    }, [formData]);

    function validate(formData, credentials) {
        let errors = {};
        if (!formData.first_name.trim()) {
            errors.first_name = 'First name is required';
        }

        if (!formData.last_name.trim()) {
            errors.last_name = 'Last name is required';
        }

        if (!phoneInput.isValid) {
            errors.phone = 'Phone number is invalid';
        }

        if (!formData.preferred_email) {
            errors.preferred_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.preferred_email)) {
            errors.preferred_email = 'Email address is invalid';
        }

        if (!formData.date_of_birth) {
            errors.date_of_birth = 'Date of Birth is required';
        }

        if (!formData.employment_status) {
            errors.employment_status = 'Employment Status is required';
        }

        if (!formData.location) {
            errors.location = 'Location is required';
        }

        let credentialsErrors = [];

        if (credentials.length === 0) {
            credentialsErrors[0] = {organization: 'At least one credential is required.'};
        } else {
            credentials.forEach((credential, index) => {
                let credentialErrors = [];
                if (!credential.organization) {
                    credentialErrors.organization = 'Organization is required';
                }

                if (!credential.memberNumber) {
                    if (hasPADIWithMemberNumber && credential.organization === "EFR") {
                        // do nothing
                    } else {
                        credentialErrors.memberNumber = 'Member Number is required';
                    }
                }

                if (!credential.proLevel) {
                    credentialErrors.proLevel = 'Professional Level is required';
                }

                if (Object.keys(credentialErrors).length > 0) {
                    credentialsErrors[index] = credentialErrors;
                }
            });
        }

        if (credentialsErrors.length > 0) {
            errors.credentials = credentialsErrors;
        }

        return errors;
    }

    useEffect(() => {
        async function fetchGeoLocation() {
            const apiName = 'InstrOfScubaAPI'
            const path = '/get-geolocation'
            const options = {
                method: 'GET',
                queryParams: {location: locationInput.rawInput},
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            try {
                const {body} = await get({apiName, path, options}).response;
                const data = await body.json();
                const formattedAddress = data.formattedAddress;
                const lat = data.lat;
                const long = data.long;

                setLocationInput(prevState => ({...prevState, formattedAddress, lat, long}));
                setFormData({...formData, location: formattedAddress, latitude: lat, longitude: long});
            } catch (error) {
                console.log(error);
                setErrorInfo('InstructorProfile-fetchGeoLocation\n' + error);
            }
        }

        if (isAuthenticated) {
            if (locationInput.rawInput?.length > 3) {  // Only start geocoding when input length > 3
                fetchGeoLocation();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInput.rawInput, dispatch]);

    useEffect(() => {
        const validatePhone = debounce(async () => {
            const apiName = 'InstrOfScubaAPI'
            const path = '/parse-phone'
            const options = {
                method: 'GET',
                queryParams: {phone: phoneInput.rawInput},
                headers: {'Content-Type': 'application/json'}
            };

            try {
                const {body} = await get({apiName, path, options}).response;
                const data = await body.json();
                const isPossible = data.isPossible;
                const isValid = data.isValid;
                const internationalFormat = data.internationalFormat;
                const nationalFormat = data.nationalFormat;

                if (isPossible && isValid) {
                    setPhoneInput(prevState => ({
                        ...prevState,
                        isPossible,
                        isValid,
                        internationalFormat,
                        nationalFormat
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phone: internationalFormat,
                        phone_display: nationalFormat
                    }));
                } else {
                    setPhoneInput(prevState => ({
                        ...prevState,
                        isPossible,
                        isValid,
                        internationalFormat,
                        nationalFormat: 'Invalid Number'
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        phone: '',
                        phone_display: 'Invalid Number'
                    }));
                }
            } catch (error) {
                setErrorInfo('InstructorProfile-validatePhone\n' + error);
            }
        }, 300);

        if (isAuthenticated && phoneInput.rawInput?.length >= 8) {
            validatePhone();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneInput.rawInput, dispatch]);

    const handleButtonChange = (courseIndex, value) => {
        if (courseIndex >= 0 && courseIndex < courses.length) {
            setCourses(prevCourses => {
                const updatedCourses = [...prevCourses];
                updatedCourses[courseIndex].selected = value;
                return updatedCourses;
            });
        } else {
            setErrorInfo('handleButtonChange - courseIndex out of bounds');
        }
        setFormStatus(null);
    };

    const [instrCourses, setInstrCourses] = useState([]);
    const [courses, setCourses] = useState([]);

    const fetchCoursesByOrganization = async (credentials) => {
        try {
            const stringifiedCredentials = JSON.stringify(credentials);
            const encodedCredentials = encodeURIComponent(stringifiedCredentials);
            const restOperation = get({
                apiName: 'InstrOfScubaAPI',
                path: '/get-courses-by-org',
                options: {
                    headers: {'Content-Type': 'application/json'},
                    queryParams: {credentials: encodedCredentials},
                }
            });
            // const apiName = 'InstrOfScubaAPI';
            // const path = '/get-courses-by-org';
            // const options = {
            //     method: 'GET',
            //     queryParams: {credentials: credentials},
            //     headers: {
            //         'Content-Type': 'application/json',
            //     }
            // };

            // const {body} = await get({apiName, path, options}).response;
            const {body} = await restOperation.response;
            const data = await body.json();
            const instrCourseCodes = instrCourses.map(course => course.course_code);
            const formattedCourses = data.map(course => ({
                code: course.code,
                org: course.org,
                name: course.name,
                selected: instrCourseCodes ? instrCourseCodes.includes(course.code) : false
            }));
            setCourses(formattedCourses);
        } catch (error) {
            setErrorInfo('InstructorProfile-fetchCoursesByOrganization\n' + error);
        }
    };

    useEffect(() => {
        if (firstCredentialOrganization && firstCredentialProLevel) {
            fetchCoursesByOrganization(credentials);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstCredentialOrganization, firstCredentialProLevel, credentials]);

    const addCredential = () => {
        setCredentials([...credentials, {organization: '', memberNumber: '', proLevel: ''}]);
    }

    const removeCredential = (index) => {
        if (index >= 0 && index < credentials.length) {
            const newCredentials = [...credentials];
            newCredentials.splice(index, 1);
            setCredentials(newCredentials);
        } else {
            setErrorInfo('removeCredential - index out of bounds');
        }
    }

    const handleCredentialChange = (index, field, value) => {
        const newCredentials = [...credentials];
        newCredentials[index][field] = value;
        setCredentials(newCredentials);
        setFormData({...formData, [field]: value});
    }

    const [proLevelData, setProLevelData] = useState({});
    useEffect(() => {
        const fetchProLevels = async () => {
            const apiName = 'InstrOfScubaAPI';
            const path = '/get-pro-levels';
            const options = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }
            try {
                const {body} = await get({apiName, path, options}).response;
                const data = await body.json();
                setProLevelData(data);
            } catch (error) {
                setErrorInfo('InstructorProfile-fetchProLevels\n' + error);
            }
        };

        fetchProLevels();
    }, [])

    const allOrganizations = Object.keys(proLevelData);

    const unusedOrganizations = allOrganizations.filter(org => {
        const orgBranches = proLevelData[org] || {};
        return Object.keys(orgBranches).some(branch => {
            return !credentials.some(credential => credential.organization === org && credential.branch === branch);
        });
    });

    const findUsedBranches = (org, currentCredentialIndex, allCredentials) => {
        const filteredCredentials = allCredentials.filter((fc, idx) => idx !== currentCredentialIndex && fc.organization === org);
        const branches = filteredCredentials.map(fc => getBranchForProLevel(org, fc.proLevel));
        return Array.from(new Set(branches.filter(Boolean)));
    };

    function getBranchForProLevel(org, proLevel) {
        const branches = proLevelData[org];
        for (let branch in branches) {
            if (branches[branch].some(pl => pl.pro_level === proLevel)) {
                return branch;
            }
        }
        return null;
    }

    const BIO_QUESTIONS_LIST = [
        {key: 'question1', text: 'What made you choose a career in scuba diving or related fields?'},
        {
            key: 'question2',
            text: "What notable achievement or certification are you most proud of?"
        },
        {key: 'question3', text: "Where were you born and where have you lived throughout your life?"},
        {key: 'question4', text: "How many years have you been in the industry?"},
        {key: 'question5', text: "Have you worked or dived in any particularly interesting or unique locations?"},
        {
            key: 'question6',
            text: "What's your teaching or guiding philosophy when working with students or groups?"
        },
        {key: 'question7', text: 'Outside of scuba diving, what are some of your hobbies or interests?'},
        {key: 'question8', text: "What's your most memorable underwater encounter or experience?"},
        {key: 'question9', text: "Are there any places or dive sites on your bucket list? Why?"},
        {key: 'question10', text: 'How would your friends or peers describe you in a few words?'},
        {key: 'question11', text: "What do you enjoy most about the underwater world or marine life?"},
        {key: 'question12', text: "How did you first get into scuba diving? What sparked your interest?"},
        {key: 'question13', text: "What's the most rewarding aspect of your profession or hobby?"},
        {key: 'question14', text: "Are there any conservation or environmental causes you're passionate about?"},
        {
            key: 'question15',
            text: "If you could give one piece of advice to someone new to diving, what would it be?"
        },
        {key: 'question16', text: "Any interesting anecdotes or stories from your diving experiences?"},
    ];
    const INITIAL_BIO_ANSWERS = BIO_QUESTIONS_LIST.reduce((acc, question) => {
        acc[question.key] = '';
        return acc;
    }, {});
    const [showGuidedInterview, setShowGuidedInterview] = useState(false);
    const [bioAnswers, setBioAnswers] = useState(INITIAL_BIO_ANSWERS);
    const [bioVersions, setBioVersions] = useState({});
    const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
    const [isBioGenerating, setIsBioGenerating] = useState(false);
    const [refinementsHistory, setRefinementsHistory] = useState([]);
    // const [refinements, setRefinements] = useState('');
    const refinementsRef = useRef(null);
    const currentBioVersion = bioVersions[currentVersionIndex];

    const toggleGuidedInterview = () => setShowGuidedInterview(!showGuidedInterview);

    const handleGuidedInterviewSubmit = async () => {
        setIsBioGenerating(true);
        const jwtToken = await getJwtToken();
        startSubscription(jwtToken);

        const apiName = 'InstrOfScubaAPI';
        const path = '/generate-bio';
        const options = {
            method: 'POST',
            body: {
                bio_questions: BIO_QUESTIONS_LIST,
                bio_answers: bioAnswers,
                credentials: credentials,
                location: locationInput.formattedAddress,
                courses: courses
            },
            headers: {'Content-Type': 'application/json', 'x-auth-token': `Bearer ${jwtToken}`}
        };

        try {
            await post({apiName, path, options});
        } catch (error) {
            setIsBioGenerating(false);
            setErrorInfo('handleGuidedInterviewSubmit - /generate-bio\n' + error);
        }
    };

    const handleGuidedInterviewRefinementsSubmit = async () => {
        setIsBioGenerating(true);
        const refinements = refinementsRef.current.value;
        setRefinementsHistory(prevHistory => [...prevHistory, refinements]);
        const jwtToken = await getJwtToken();
        startSubscription(jwtToken);

        const apiName = 'InstrOfScubaAPI';
        const path = '/generate-bio';
        const options = {
            method: 'GET',
            queryParams: {
                about_me: formData.about_me,
                refinements: refinements,
                refinementsHistory: [...refinementsHistory, refinements]
            },
            headers: {'Content-Type': 'application/json', 'x-auth-token': `Bearer ${jwtToken}`}
        };

        try {
            await get({apiName, path, options});
        } catch (error) {
            setIsBioGenerating(false);
            setErrorInfo('handleGuidedInterviewRefinementSubmit - /generate-bio\n' + error);
        }
    };

    useEffect(() => {
        dispatch(saveBioVersions(bioVersions));
        localStorage.setItem('bioVersions', JSON.stringify(bioVersions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bioVersions])

    const goToNextVersion = () => {
        if (currentVersionIndex < bioVersions.length - 1) {
            const newIndex = currentVersionIndex + 1;
            setCurrentVersionIndex(newIndex);
            setFormData(prevFormData => ({
                ...prevFormData,
                about_me: bioVersions[newIndex]
            }));
        }
    };

    const goToPreviousVersion = () => {
        if (currentVersionIndex > 0) {
            const newIndex = currentVersionIndex - 1;
            setCurrentVersionIndex(newIndex);
            setFormData(prevFormData => ({
                ...prevFormData,
                about_me: bioVersions[newIndex]
            }));
        }
    };

    const handleVersionChange = (idx) => {
        setCurrentVersionIndex(idx);
        setFormData(prevFormData => ({
            ...prevFormData,
            about_me: bioVersions[idx]
        }));
    };

    const [subscription, setSubscription] = useState(null);

    const startSubscription = (jwtToken) => {
        // console.log(jwtToken);
        // const decodedToken = jwtDecode(jwtToken);
        // const owner = decodedToken['cognito:username'];
        // const owner = 'fbf0ac9d-5032-45da-8dad-c357718765b3';
        const client = generateClient();
        //     headers: {
        //         // 'x-auth-token': `Bearer ${jwtToken}`,
        //         Authorization: jwtToken,
        //         // testHeader: 'test-value',
        //         authToken: jwtToken
        //     },
        // });

        const newSubscription = client.graphql({query: subscriptions.onUpdateBio}).subscribe({
            next: (bioData) => {
                const updatedBio = bioData.value.data.onUpdateBio;
                if (updatedBio && updatedBio.status === 'completed') {

                    setFormData(prevFormData => ({...prevFormData, about_me: updatedBio.content}));

                    setBioVersions(prevBioVersions => {
                        const newVersions = [...(prevBioVersions || []), updatedBio.content];
                        setCurrentVersionIndex(newVersions.length - 1);
                        return newVersions;
                    });

                    setCurrentVersionIndex(prevBioVersions => (prevBioVersions ? prevBioVersions.length : 0));
                    setIsBioGenerating(false);
                    newSubscription.unsubscribe();
                } else {
                    console.log('bio not ready yet');
                }
            },
            error: (err) => {
                console.error(err);
                setIsBioGenerating(false);
            }
        });
        setSubscription(newSubscription);
    };

    useEffect(() => {
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [subscription]);

    return (
        <div data-testid="instructor-profile">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                <LoadingSpinner isLoading={!isLoaded}/>
                {showModal && (<ResultModal result={resultModalObj} closeModal={closeModal}/>)}
                <main>
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Instructor Information
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This information is used to confirm your status as a scuba
                                    professional
                                    and will be displayed publicly throughout your website as well as in
                                    the
                                    instructor directory where students can find instructors near them
                                    who
                                    teach specific courses they are interested in. Date of birth and
                                    employment type are not publicly visible. The Matched Location and
                                    associated latitude/longitude will be used in place of your actual
                                    input;
                                    type your location in whatever format you prefer (city, state, zip,
                                    etc.), and be as vague or as specific as you like.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label htmlFor="first_name"
                                               className={`block text-sm font-medium leading-6 ${formErrors.first_name ? 'text-red-900' : 'text-gray-900'}`}>
                                            First Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="first_name"
                                                ref={firstNameRef}
                                                value={formData.first_name || ''}
                                                onChange={handleChange}
                                                id="first_name"
                                                autoComplete="given-name"
                                                className={`block w-full rounded-md border-0 py-1.5 ${formErrors.first_name ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="middle_name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Middle Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="middle_name"
                                                value={formData.middle_name || ''}
                                                onChange={handleChange}
                                                id="middle_name"
                                                autoComplete="additional-name"
                                                className="h-8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="last_name"
                                               className={`block text-sm font-medium leading-6 ${formErrors.last_name ? 'text-red-900' : 'text-gray-900'}`}>
                                            Last Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="last_name"
                                                ref={lastNameRef}
                                                value={formData.last_name || ''}
                                                onChange={handleChange}
                                                id="last_name"
                                                autoComplete="family-name"
                                                className={`block w-full rounded-md border-0 py-1.5 ${formErrors.last_name ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label htmlFor="phone"
                                               className={`block text-sm font-medium leading-6 ${formErrors.phone ? 'text-red-900' : 'text-gray-900'}`}>
                                            Phone Number (with Country Code)
                                        </label>
                                        <div className="mt-2">
                                            <div
                                                className={`flex bg-white items-center rounded-md ring-1 ring-inset h-8 ${formErrors.phone ? 'ring-red-300 focus-within:ring-red-500' : 'ring-gray-300 focus-within:ring-indigo-600'}`}>
                                                            <span
                                                                className="pl-3 text-gray-500 sm:text-sm">+</span>
                                                <input
                                                    id="phone"
                                                    ref={phoneRef}
                                                    name="phone"
                                                    value={phoneInput.rawInput || ''}
                                                    onChange={handleChange}
                                                    type="tel"
                                                    autoComplete="tel"
                                                    className={`h-8 flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${formErrors.phone ? 'text-red-900 placeholder:text-red-400' : ''}`}
                                                />
                                            </div>
                                            <small
                                                className={`block mt-2 ${formErrors.phone ? 'text-red-900' : 'text-gray-600'}`}>{phoneInput.nationalFormat ? 'Local Format: ' + phoneInput.nationalFormat : 'Enter country code and number, we will do the formatting for you.'}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="preferred_email"
                                               className={`block text-sm font-medium leading-6 ${formErrors.preferred_email ? 'text-red-900' : 'text-gray-900'}`}>
                                            Email Address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="preferred_email"
                                                name="preferred_email"
                                                ref={emailRef}
                                                value={formData.preferred_email || ''}
                                                onChange={handleChange}
                                                type="email"
                                                autoComplete="email"
                                                aria-invalid={formErrors.preferred_email ? 'true' : 'false'}
                                                className={`h-8 block w-full rounded-md border-0 py-1.5 ${formErrors.preferred_email ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                            />
                                            {formErrors.preferred_email && (
                                                <p className="mt-2 text-sm text-red-900" id="preferred-email-error">
                                                    {formErrors.preferred_email}
                                                </p>
                                            )}
                                        </div>
                                        <p className="mt-2 text-sm text-gray-500">
                                            Changing this email address will not change
                                            the email you use to log in, but it will be used throughout
                                            your website and for all communications with students.
                                        </p>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="date_of_birth"
                                               className={`block text-sm font-medium leading-6 ${formErrors.date_of_birth ? 'text-red-900' : 'text-gray-900'}`}>
                                            Date of Birth
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="date"
                                                name="date_of_birth"
                                                ref={dobRef}
                                                value={formData.date_of_birth || ""}
                                                onChange={handleChange}
                                                id="date_of_birth"
                                                autoComplete="bday"
                                                className={`h-8 block w-full rounded-md border-0 py-1.5 ${formErrors.date_of_birth ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label htmlFor="employment_status"
                                               className={`block text-sm font-medium leading-6 ${formErrors.employment_status ? 'text-red-900' : 'text-gray-900'}`}>
                                            Employment Type
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="employment_status"
                                                name="employment_status"
                                                ref={employmentStatusRef}
                                                value={formData.employment_status || ''}
                                                onChange={handleChange}
                                                className={`h-8 block w-full rounded-md border-0 py-1.5 ${formErrors.employment_status ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                            >
                                                <option value=""></option>
                                                <option value="employee">Dive Shop Employee</option>
                                                <option value="independant-contractor">Independent
                                                    Contractor at Dive Shop
                                                </option>
                                                <option value="business-owner">Business Owner</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <div>
                                            <label htmlFor="location"
                                                   className={`block text-sm font-medium leading-6 ${formErrors.location ? 'text-red-900' : 'text-gray-900'}`}>
                                                Location
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    id="location"
                                                    value={locationInput.rawInput || ''}
                                                    onChange={handleChange}
                                                    className={`h-8 block w-full rounded-md border-0 py-1.5 ${formErrors.location ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                                />
                                                <small
                                                    className="block mt-2 text-gray-600">{locationInput.formattedAddress ? 'Matched Location: ' + locationInput.formattedAddress : 'Try a location name, such as a city and state, a zipcode, country name, etc.'}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="store_resort"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Store / Resort Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="store_resort"
                                                value={formData.store_resort || ''}
                                                onChange={handleChange}
                                                id="store_resort"
                                                className="h-8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Instructor Credentials
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    Select the highest level of professional certification you have
                                    achieved
                                    with each organization. Multiple professional levels within an
                                    organization may be selected from different groups, such as Scuba,
                                    Freediving, Technical Diving, etc. This will generate the list of
                                    available
                                    courses below, under Course Offerings. The first organization you
                                    select
                                    will be used to brand your website with related marketing logos and
                                    images. A Pro Check will be conducted to ensure you are eligible to
                                    use
                                    these materials.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    {credentials.map((credential, index) => {
                                        const usedBranches = findUsedBranches(credential.organization, index, credentials);
                                        const availableProLevels = Object.entries(proLevelData[credential.organization] || {})
                                            .filter(([branch, proLevels]) => !usedBranches.includes(branch))
                                            .flatMap(([branch, proLevels]) => proLevels);
                                        const disableMemberNumberForEFR = credential.organization === "EFR" & hasPADIWithMemberNumber;

                                        return (
                                            <React.Fragment key={index}>
                                                <div className="sm:col-span-2">
                                                    <div className="flex items-start">
                                                        <div className="flex flex-col self-end">
                                                            <button type="button"
                                                                    disabled={index === 0}
                                                                    onClick={() => removeCredential(index)}
                                                                    className={`self-start ${index === 0 ? 'text-gray-500' : 'text-gray-900 hover:text-red-500'}`}>
                                                                <AiOutlineMinusCircle
                                                                    className="w-6 h-6 mb-1.5"/>
                                                            </button>
                                                        </div>
                                                        <div className="flex flex-col ml-4 w-full">
                                                            <label htmlFor="organization"
                                                                   className={`block text-sm font-medium leading-6 ${formErrors.credentials?.[index]?.organization ? 'text-red-900' : 'text-gray-900'}`}>
                                                                Organization
                                                            </label>
                                                            <div className="mt-2">
                                                                <select
                                                                    id="organization"
                                                                    value={credential.organization || ''}
                                                                    onChange={(e) => handleCredentialChange(index, 'organization', e.target.value)}
                                                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.credentials?.[index]?.organization ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                                                >
                                                                    <option value=""></option>
                                                                    {unusedOrganizations.map((org) => (
                                                                        <option key={org}
                                                                                value={org}>{org}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="highest_pro_level"
                                                           className={`block text-sm font-medium leading-6 ${formErrors.credentials?.[index]?.proLevel ? 'text-red-900' : 'text-gray-900'}`}>
                                                        Pro Level
                                                    </label>

                                                    <div className="mt-2">
                                                        <select
                                                            id="highest_pro_level"
                                                            value={credential.proLevel}
                                                            onChange={(e) => handleCredentialChange(index, 'proLevel', e.target.value)}
                                                            className={`block w-full rounded-md border-0 py-1.5 ${formErrors.credentials?.[index]?.proLevel ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                                        >
                                                            <option value=""></option>
                                                            {
                                                                availableProLevels.map(pl => (
                                                                    <option
                                                                        key={`${pl.organization}-${pl.pro_level}`}
                                                                        value={pl.pro_level}>
                                                                        {pl.pro_level}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="member_number"
                                                           className={`block text-sm font-medium leading-6 ${formErrors.credentials?.[index]?.memberNumber ? 'text-red-900' : 'text-gray-900'}`}>
                                                        Member Number
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="member_number"
                                                            type="text"
                                                            value={credential.memberNumber}
                                                            onChange={(e) => handleCredentialChange(index, 'memberNumber', e.target.value)}
                                                            disabled={disableMemberNumberForEFR}
                                                            aria-invalid={formErrors.credentials?.[index]?.memberNumber ? 'true' : 'false'}
                                                            className={`px-3 block h-9 w-full rounded-md border-0 py-1.5 ${formErrors.credentials?.[index]?.memberNumber ? 'text-red-900 ring-red-300 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${disableMemberNumberForEFR ? 'bg-gray-100' : ''}`}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}

                                    <button type="button" onClick={addCredential}>
                                        <AiOutlinePlusCircle
                                            className="w-6 h-6 text-gray-900 hover:text-green-500"/>
                                    </button>
                                </div>

                            </div>

                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Profile Photo
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    Upload a quality photo to be displayed throughout your website.
                                    We will automatically resize it for you, to include any cropping
                                    needing to make it square without distorting the
                                    image. {(!instrOrgMemNum) && (
                                    <span className="text-red-900">You must select an organization and enter your member number before uploading a photo.</span>
                                )}
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    <div className="col-span-full">
                                        <label htmlFor="photo"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Photo
                                        </label>
                                        <div className="mt-2">
                                            <div className="w-60">
                                                {isPhotoUploading ? (
                                                    <BounceLoader color={"#3b82f6"} size={240}/>
                                                ) : (
                                                    <img alt="Instructor Portrait"
                                                         src={instrImageUrl}
                                                         onError={(e) => {
                                                             e.target.onerror = null;
                                                             e.target.src = "/images/portraits/unknown_male.png"
                                                         }} className="h-60 text-gray-300"
                                                         aria-hidden="true"/>
                                                )}
                                                <button
                                                    id="photo"
                                                    type="button"
                                                    className={`rounded-md bg-white w-full px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-2 ${(!instrOrgMemNum) ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    onClick={uploadPhoto}
                                                    disabled={!instrOrgMemNum || isPhotoUploading}
                                                    title={!instrOrgMemNum ? 'You must select an organization and enter your member number before uploading a photo.' : ""}
                                                >
                                                    Change
                                                </button>
                                            </div>
                                            <input data-testid="file-input" type="file" ref={fileInput}
                                                   onChange={handlePhotoChange}
                                                   className="hidden" accept="image/*"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <SocialMediaForm formData={formData} handleChange={handleChange}/>

                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Course Offerings
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    Based on your credentials, these are the courses we believe you may
                                    be authorized to conduct. Select those you are willing and able to
                                    provide. Students searching for an instructor will be able to filter
                                    by
                                    these courses/events. Additionally, members on the sea turtle and
                                    dolphin tiers will have a dedicated Courses page on their website
                                    offering the courses selected below.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="about_me"
                                               className="flex items-center text-sm font-medium leading-6 text-gray-900">
                                            Do you offer any of the following courses?
                                        </label>
                                        <div className="mt-2 space-y-4">
                                            {firstCredentialOrganization ? (
                                                courses.length === 0 ? (
                                                    <div className="text-gray-500">No courses available
                                                        for the selected organization.
                                                    </div>
                                                ) : (
                                                    courses.map((course, i) => (
                                                        <div key={course.code} id={`course-${i}`}
                                                             className="grid grid-cols-[1fr,auto] gap-4 items-center border border-gray-300 rounded-md p-2">
                                                            <div
                                                                className="text-lg text-gray-700">{course.org} {course.name}</div>
                                                            <span
                                                                className="isolate inline-flex rounded-md shadow-sm">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleButtonChange(i, true)}
                                                                        className={`relative inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                                                            course.selected === true ? 'text-white bg-green-500' : 'text-gray-900 bg-white'
                                                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-l-md`}
                                                                    >
                                                                      Yes
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleButtonChange(i, false)}
                                                                        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ${
                                                                            course.selected === false ? 'text-white bg-red-500' : 'text-gray-900 bg-white'
                                                                        } ring-1 ring-inset ring-gray-300 focus:z-10 rounded-r-md`}
                                                                    >
                                                                      No
                                                                    </button>
                                                                </span>
                                                        </div>
                                                    ))
                                                )
                                            ) : (
                                                <div className="text-gray-500">
                                                    Please select an organization to see a list of
                                                    courses.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    About Me
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    Writing an "About Me" can be challenging, but it's a valuable way to
                                    introduce yourself and showcase your unique qualities. Think about
                                    what makes you stand out, your professional journey, and what you're
                                    passionate about. Aim for a balance between personal and
                                    professional details. If you're having a hard time crafting the
                                    perfect
                                    message, use our guided interview process to answer a series of
                                    questions as short bullet points and our ChatGPT-4 powered AI Scuba
                                    Master will craft a biography for you. You can then edit the
                                    biography
                                    as you see fit.
                                </p>
                                <div className="mt-4 text-sm leading-6 text-gray-600">
                                    <span className="mr-2">Guided Interview:</span>
                                    <button
                                        type="button"
                                        className={`bg-indigo-500 text-white px-3 py-1 rounded-l-md ${showGuidedInterview ? '' : 'bg-opacity-50'}`}
                                        onClick={toggleGuidedInterview}>
                                        Show
                                    </button>
                                    <button
                                        type="button"
                                        className={`bg-indigo-500 text-white px-3 py-1 rounded-r-md ${showGuidedInterview ? 'bg-opacity-50' : ''}`}
                                        onClick={toggleGuidedInterview}>
                                        Hide
                                    </button>
                                </div>

                                {showGuidedInterview && (
                                    <>
                                        <h2 className="mt-4 text-base font-semibold leading-7 text-gray-900">
                                            Guided Interview
                                        </h2>
                                        <p className="mt-4 text-sm leading-6 text-gray-600">
                                            Answer as many or as few questions as you like. You can
                                            always
                                            come back and answer more later. Don't worry about complete
                                            sentences or trying to find the right words. Just answer the
                                            questions as short bullet points with the first thing that
                                            comes
                                            to your mind.
                                        </p>
                                        <div
                                            className="mt-4">
                                            {BIO_QUESTIONS_LIST.map((question) => (
                                                <div key={question.key} className="mt-4">
                                                    <label htmlFor={question.key}
                                                        // className="flex items-center text-sm font-medium leading-6 text-gray-900">
                                                           className="mb-2 block text-sm font-medium text-gray-900">
                                                        {question.text}
                                                    </label>
                                                    <input type="text"
                                                           className="h-8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                           id={question.key}
                                                           value={bioAnswers[question.key] || ''}
                                                           onChange={(e) => setBioAnswers({
                                                               ...bioAnswers,
                                                               [question.key]: e.target.value
                                                           })}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <button
                                            data-testid="guided-interview-submit"
                                            type="button"
                                            disabled={isBioGenerating}
                                            className="mt-4 bg-indigo-500 text-white px-3 py-1 rounded-md ml-2 hover:bg-indigo-600 focus:outline-none focus:bg-indigo-700 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            onClick={handleGuidedInterviewSubmit}>
                                            {isBioGenerating ? 'Generating...' : 'Generate About Me'}
                                        </button>
                                    </>
                                )}

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="about_me"
                                               className="flex items-center text-sm font-medium leading-6 text-gray-600">
                                            About Me
                                        </label>
                                        <div className="mt-2">
                                            <div
                                                className="flex bg-white rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                                {isBioGenerating ? (
                                                    <BounceLoader color={"#3b82f6"} size={240}/>
                                                ) : (
                                                    <textarea
                                                        name="about_me"
                                                        value={Array.isArray(currentBioVersion) ? currentBioVersion.join('\n\n') : currentBioVersion || ''}
                                                        onChange={handleAboutMeChange}
                                                        id="about_me"
                                                        rows="20"
                                                        className="w-full block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <BioPagination
                                            bioVersions={bioVersions}
                                            currentVersionIndex={currentVersionIndex}
                                            handleVersionChange={handleVersionChange}
                                            goToPreviousVersion={goToPreviousVersion}
                                            goToNextVersion={goToNextVersion}
                                        />
                                        <div className="mt-4">
                                            <label htmlFor="refinements"
                                                   className="mb-2 block text-sm font-medium text-gray-900">
                                                What refinements would you like ChatGPT to make to
                                                your About Me?
                                            </label>
                                            <input type="text"
                                                   className="h-8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                   id="refinements"
                                                   ref={refinementsRef}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="mt-4 bg-indigo-500 text-white px-3 py-1 rounded-md ml-2 hover:bg-indigo-600 focus:outline-none focus:bg-indigo-700 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            onClick={handleGuidedInterviewRefinementsSubmit}>
                                            Refine About Me
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <PaymentOptions formData={formData} handleChange={handleChange}/>

                        </div>
                        <div className="mt-6 flex justify-end gap-x-6">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                        {
                            formStatus && (formStatus.error ?
                                    <div data-testid="form-error"
                                        className="w-full rounded-md bg-red-200 text-red-500 text-center font-bold py-2 mt-2">{formStatus.error}</div>
                                    :
                                    <div
                                        className="w-full rounded-md bg-green-200 text-green-500 text-center font-bold py-2 mt-2">{formStatus.success}</div>

                            )
                        }
                    </form>
                </main>
            </div>
        </div>
    )
};

export default withAuth(InstructorProfile, {showIntro: true});


