import './App.css';
import '@aws-amplify/ui-react/styles.css'
import React, {useEffect, useState} from 'react'
import Header from './containers/Header';
import {Route, Routes, useLocation} from "react-router-dom";
import Footer from "./containers/Footer";
import {useDispatch, useSelector} from 'react-redux';
import {checkUser} from "./redux/authSlice";
import {setCurrentURL} from "./redux/urlSlice";
import {fetchInstrDetails} from "./redux/instrDetailsSlice";
import {LoadingSpinner} from "./containers/LoadingSpinner";
import UnlistedDomainHeader from "./containers/UnlistedDomainHeader";
import UnlistedDomainFooter from "./containers/UnlistedDomainFooter";
import LandingPage from "./containers/LandingPage";
import HeroCarouselWrapper from "./containers/HeroCarouselWrapper";
import SignInPage from "./containers/SignInPage";
import Courses from "./containers/Courses";
import PadiScubaDiver from "./components/PADICourses/PadiScubaDiver";
import PadiOpenWater from "./components/PADICourses/PadiOpenWater";
import PadiAdventure from "./components/PADICourses/PadiAdventure";
import PadiAdvancedOpenWater from "./components/PADICourses/PadiAdvancedOpenWater";
import PadiEmergencyFirstResponse from "./components/PADICourses/PadiEmergencyFirstResponse";
import PadiRescue from "./components/PADICourses/PadiRescue";
import PadiReActivate from "./components/PADICourses/PadiReActivate";
import PadiDiscoverLocalDiving from "./components/PADICourses/PadiDiscoverLocalDiving";
import PadiNitrox from "./components/PADICourses/PadiNitrox";
import Error404 from "./containers/404Page";
import PadiDeep from "./components/PADICourses/PadiDeep";
import PadiDrySuit from "./components/PADICourses/PadiDrySuit";
import PadiUnderwaterNavigator from "./components/PADICourses/PadiUnderwaterNavigator";
import PadiPeakPerformanceBuoyancy from "./components/PADICourses/PadiPeakPerformanceBuoyancy";
import PadiWreck from "./components/PADICourses/PadiWreck";
import PadiNight from "./components/PADICourses/PadiNight";
import PadiSearchRecovery from "./components/PADICourses/PadiSearchRecovery";
import PadiDrift from "./components/PADICourses/PadiDrift";
import PadiBoat from "./components/PADICourses/PadiBoat";
import PadiDelayedSurfaceMarker from "./components/PADICourses/PadiDelayedSurfaceMarker";
import PadiSelfReliant from "./components/PADICourses/PadiSelfReliant";
import PadiMasterScubaDiver from "./components/PADICourses/PadiMasterScubaDiver";
import PadiDivemaster from "./components/PADICourses/PadiDivemaster";
import StudentProfile from "./containers/StudentProfile";
import ChangePassword from "./containers/ChangePassword";
import ContactInstructor from "./containers/ContactInstr";
import About from "./containers/About";
import DiverMedicalForm from "./containers/forms/DiverMedicalForm";
import DiverMedicalForm2 from "./containers/forms/DiverMedicalForm2";
import DiverMedicalForm3 from "./containers/forms/DiverMedicalForm3";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import InstructorDirectory from "./containers/InstructorDirectory";
import AboutInstructor from "./containers/AboutInstructor";
import ContactSupport from "./containers/ContactSupport";
import ProspectiveInstrLandingPage from "./containers/ProspectiveInstrLandingPage";
import InstructorProfile from "./containers/InstructorProfile";
import ManageSubscription from "./containers/ManageSubscription";
import ManageCalendar from "./containers/ManageCalendar";
import DiverActivitiesForm from "./containers/forms/DiverActivitiesForm";
import LiabilityReleaseForm from "./containers/forms/LiabilityReleaseForm";
import CeadForm from "./containers/forms/CeadForm";
import SafeDivingForm from "./containers/forms/SafeDivingForm";
import OutstandingForms from "./containers/OutstandingForms";
import OutstandingCharges from "./containers/OutstandingCharges";
import Error403 from "./containers/403Page";
import EventDetailsSelector from "./containers/EventDetailsSelector";
import CalendarPage from "./containers/CalendarPage";
import EfrPrimSecCare from "./components/PADICourses/EfrPrimSecCare";
import StudentList from "./containers/StudentList";
import Certifications from "./containers/Certifications";
import Transactions from "./containers/Transactions";
import TransactionDetails from "./containers/TransactionDetails";
import SubscriptionGateway from "./containers/SubscriptionGateway";
import StudentCourses from "./containers/StudentCourses";
import StudentEvents from "./containers/StudentEvents";
import StudentCharges from "./containers/StudentCharges";
import SubscriptionCancel from "./containers/SubscriptionCancel";
import SubscriptionSuccess from "./containers/SubscriptionSuccess";
import BarnacleTier from "./components/BarnacleTier";
import {fetchEvents} from "./redux/eventsSlice";

const App = () => {
    const dispatch = useDispatch();
    const currentURL = useSelector((state) => state.url.currentURL);
    const {pathname} = useLocation();
    const [isUnlistedDomain, setIsUnlistedDomain] = useState(false);
    const instrDetailsLoading = useSelector((state) => state.instrDetails.status === 'loading');
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);
    const instrDetailsError = useSelector((state) => state.instrDetails.error);
    const location = useLocation();
    const isProspectiveInstrPage = location.pathname === "/prospective-instructor";
    const [errorInfo, setErrorInfo] = useState(null);

    useEffect(() => {
        if (instrDetails.domain_exists === false) {
            // console.log('Redirecting to instructorofscuba.com');
            window.location.href = 'https://www.instructorofscuba.com';
        }
    }, [instrDetails]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const currentHostname = window.location.hostname;
        const domainsToSkip = ['localhost', 'www.instructorofscuba.com', 'instructorofscuba.local'];
        if (domainsToSkip.includes(currentHostname)) {
            setIsUnlistedDomain(true);
            return;
        }

        if (currentURL !== currentHostname) {
            dispatch(setCurrentURL(currentHostname));
            dispatch(fetchInstrDetails()).unwrap()
                .then((data) => {
                    if (data.domain_exists === true) {
                        const currentDate = new Date();
                        dispatch(fetchEvents({
                            instr_id: data.instr_id,
                            month: currentDate.getMonth() + 1,  // JavaScript months are 0-indexed
                            year: currentDate.getFullYear()
                        }))
                    }
                })
                .catch((error) => {
                    setErrorInfo('App-fetchInstrDetails\n' + error);
                });
        }
    }, [currentURL, dispatch]);

    useEffect(() => {
        dispatch(checkUser());
    }, [dispatch]);

    useEffect(() => {
        if (instrDetailsError) {
            setErrorInfo('App-instrDetailsError\n' + instrDetailsError);
        }
    }, [instrDetailsError]);

    if (errorInfo) {
        throw errorInfo;
    }

    const renderBasedOnDomainExists = () => {
        return (
            <>
                <div data-testid="app-root">{isUnlistedDomain ? <UnlistedDomainHeader/> : <Header/>}</div>
                <main>
                    <div className={`mx-auto max-w-7xl ${isProspectiveInstrPage ? '' : 'py-6 sm:px-6 lg:px-8'}`}>
                        <Routes>
                            <Route path="/" element={isUnlistedDomain ? <LandingPage/> : <HeroCarouselWrapper/>}/>
                            <Route path="/signin" element={<SignInPage/>}/>
                            <Route path="/support" element={<ContactSupport/>}/>
                            <Route path="/courses" element={isUnlistedDomain ? <Error404/> : <Courses/>}/>
                            <Route path="/courses/padi-scuba-diver"
                                   element={isUnlistedDomain ? <Error404/> : <PadiScubaDiver/>}/>
                            <Route path="/courses/padi-open-water"
                                   element={isUnlistedDomain ? <Error404/> : <PadiOpenWater/>}/>
                            <Route path="/courses/padi-adventure-diver"
                                   element={isUnlistedDomain ? <Error404/> : <PadiAdventure/>}/>
                            <Route path="/courses/padi-advanced-open-water"
                                   element={isUnlistedDomain ? <Error404/> : <PadiAdvancedOpenWater/>}/>
                            <Route path="/courses/padi-emergency-first-response"
                                   element={isUnlistedDomain ? <Error404/> : <PadiEmergencyFirstResponse/>}/>
                            <Route path="/courses/padi-rescue-diver"
                                   element={isUnlistedDomain ? <Error404/> : <PadiRescue/>}/>
                            <Route path="/courses/padi-reactivate"
                                   element={isUnlistedDomain ? <Error404/> : <PadiReActivate/>}/>
                            <Route path="/courses/padi-discover-local-diving"
                                   element={isUnlistedDomain ? <Error404/> : <PadiDiscoverLocalDiving/>}/>
                            <Route path="/courses/padi-enriched-air-nitrox"
                                   element={isUnlistedDomain ? <Error404/> : <PadiNitrox/>}/>
                            <Route path="/courses/padi-deep" element={isUnlistedDomain ? <Error404/> : <PadiDeep/>}/>
                            <Route path="/courses/padi-dry-suit"
                                   element={isUnlistedDomain ? <Error404/> : <PadiDrySuit/>}/>
                            <Route path="/courses/padi-underwater-navigator"
                                   element={isUnlistedDomain ? <Error404/> : <PadiUnderwaterNavigator/>}/>
                            <Route path="/courses/padi-peak-performance-buoyancy"
                                   element={isUnlistedDomain ? <Error404/> : <PadiPeakPerformanceBuoyancy/>}/>
                            <Route path="/courses/padi-wreck" element={isUnlistedDomain ? <Error404/> : <PadiWreck/>}/>
                            <Route path="/courses/padi-night" element={isUnlistedDomain ? <Error404/> : <PadiNight/>}/>
                            <Route path="/courses/padi-search-and-recovery"
                                   element={isUnlistedDomain ? <Error404/> : <PadiSearchRecovery/>}/>
                            <Route path="/courses/padi-drift" element={isUnlistedDomain ? <Error404/> : <PadiDrift/>}/>
                            <Route path="/courses/padi-boat" element={isUnlistedDomain ? <Error404/> : <PadiBoat/>}/>
                            <Route path="/courses/padi-delayed-surface-marker-buoy-dsmb"
                                   element={isUnlistedDomain ? <Error404/> : <PadiDelayedSurfaceMarker/>}/>
                            <Route path="/courses/padi-self-reliant"
                                   element={isUnlistedDomain ? <Error404/> : <PadiSelfReliant/>}/>
                            <Route path="/courses/padi-master-scuba-diver"
                                   element={isUnlistedDomain ? <Error404/> : <PadiMasterScubaDiver/>}/>
                            <Route path="/courses/padi-divemaster"
                                   element={isUnlistedDomain ? <Error404/> : <PadiDivemaster/>}/>
                            <Route path="/courses/efr-primary-and-secondary-care"
                                   element={isUnlistedDomain ? <Error404/> : <EfrPrimSecCare/>}/>
                            <Route path="/course-signup"
                                   element={isUnlistedDomain ? <Error404/> : <StudentProfile isCourseSignup={true}/>}/>
                            <Route path="/profile" element={<StudentProfile isCourseSignup={false}/>}/>
                            <Route path="/change-password" element={<ChangePassword/>}/>
                            <Route path="/.well-known/change-password" element={<ChangePassword/>}/>
                            <Route path="/contact" element={isUnlistedDomain ? <Error404/> : <ContactInstructor/>}/>
                            <Route path="/about" element={isUnlistedDomain ? <Error404/> : <About/>}/>
                            <Route path="/forms/diver-medical-form"
                                   element={isUnlistedDomain ? <Error404/> : <DiverMedicalForm/>}/>
                            <Route path="/forms/diver-medical-form-2"
                                   element={isUnlistedDomain ? <Error404/> : <DiverMedicalForm2/>}/>
                            <Route path="/forms/diver-medical-form-3"
                                   element={isUnlistedDomain ? <Error404/> : <DiverMedicalForm3/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/instructor-directory"
                                   element={isUnlistedDomain ? <InstructorDirectory/> : <Error404/>}/>
                            <Route path="/about-instructor"
                                   element={isUnlistedDomain ? <AboutInstructor/> : <Error404/>}/>
                            <Route path="/prospective-instructor"
                                   element={isUnlistedDomain ? <ProspectiveInstrLandingPage/> : <Error404/>}/>
                            <Route path="/instructor-profile" element={<InstructorProfile/>}/>
                            <Route path="/manage-subscription" element={<ManageSubscription/>}/>
                            <Route path="/calendar" element={<CalendarPage/>}/>
                            <Route path="/manage-calendar" element={<ManageCalendar/>}/>
                            <Route path="/forms/diver-activities-form"
                                   element={isUnlistedDomain ? <Error404/> : <DiverActivitiesForm/>}/>
                            <Route path="/forms/liability-release-form"
                                   element={isUnlistedDomain ? <Error404/> : <LiabilityReleaseForm/>}/>
                            <Route path="/forms/cead-form" element={isUnlistedDomain ? <Error404/> : <CeadForm/>}/>
                            <Route path="/forms/safe-diving-form"
                                   element={isUnlistedDomain ? <Error404/> : <SafeDivingForm/>}/>
                            <Route path="/outstanding-forms"
                                   element={isUnlistedDomain ? <Error404/> : <OutstandingForms/>}/>
                            <Route path="/outstanding-charges"
                                   element={isUnlistedDomain ? <Error404/> : <OutstandingCharges/>}/>
                            <Route path="/event/:eventId"
                                   element={isUnlistedDomain ? <Error404/> : <EventDetailsSelector/>}/>
                            <Route path="/student-list" element={isUnlistedDomain ? <Error404/> : <StudentList/>}/>
                            <Route path="/transactions" element={isUnlistedDomain ? <Error404/> : <Transactions/>}/>
                            <Route path="/transactions/:id"
                                   element={isUnlistedDomain ? <Error404/> : <TransactionDetails/>}/>
                            <Route path="/403" element={<Error403/>}/>
                            <Route path="/certifications/:id"
                                   element={isUnlistedDomain ? <Error403/> : <Certifications/>}/>
                            <Route path="/student-courses/:id"
                                   element={isUnlistedDomain ? <Error403/> : <StudentCourses/>}/>
                            <Route path="/student-events/:id"
                                   element={isUnlistedDomain ? <Error403/> : <StudentEvents/>}/>
                            <Route path="/student-charges/:id"
                                   element={isUnlistedDomain ? <Error403/> : <StudentCharges/>}/>
                            <Route path="/subscription-gateway" element={<SubscriptionGateway/>}/>
                            <Route path="/subscription-cancel" element={<SubscriptionCancel/>}/>
                            <Route path="/subscription-success" element={<SubscriptionSuccess/>}/>
                            <Route path="/barnacle-tier" element={<BarnacleTier/>}/>
                            <Route path="*" element={<Error404/>}/>
                        </Routes>
                    </div>
                </main>
                <footer>
                    {isUnlistedDomain ? <UnlistedDomainFooter/> : <Footer/>}
                </footer>
                <LoadingSpinner isLoading={instrDetailsLoading}/>
            </>
        );
    };

    return renderBasedOnDomainExists();
};

export default (App);
