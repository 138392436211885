import React from 'react';
import {FaChartLine, FaHandHoldingUsd} from 'react-icons/fa';
import {IoIosTime} from 'react-icons/io';
import {MdMessage, MdPersonAdd} from "react-icons/md";
import {FaCertificate, FaChair, FaFileInvoiceDollar, FaFileSignature, FaGlobe} from "react-icons/fa6";

const ProspectiveInstrGoalsSection = () => {
    return (
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                <div key='maximize' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaChartLine className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Maximize Earnings
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Unearth the untapped potential of your scuba
                        instructing career with our revolutionary platform designed to elevate your personal brand. No
                        longer rely solely on dive shops to fill your classes and charters. Forge direct connections
                        with customers who've experienced your underwater expertise, and invite them back for events
                        you're leading or classes your teaching.
                    </dd>
                </div>
                <div key='automate' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <IoIosTime className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Automate Tasks
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Experience the future of scuba instructing
                        with a platform designed to do the heavy lifting for you. Say goodbye to administrative chaos
                        and focus on what you do best—teaching and diving. Streamline your workflow, eliminate tedious
                        tasks, and step into a new era of efficiency.
                    </dd>
                </div>
                <div key='presence' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaGlobe className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Professional Online Presence
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Elevate your brand with a sleek,
                        forward-facing website tailored for scuba instructors. Showcase your availability, courses,
                        pricing, and essential requirements with clarity and elegance. Crafted with user experience in
                        mind, it provides a seamless way for potential customers to connect with and learn about your
                        offerings.
                    </dd>
                </div>
                <div key='signups' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <MdPersonAdd className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Streamlined Student Signups
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Enroll students with ease using our platform.
                        As students register for a course, they provide all the necessary details, from basic contact
                        information to gear sizes. After registration, students receive a digital contact card of you,
                        making it simple for them to save and reach out. At the same time, you get a digital contact
                        card of the student's details, so you can effortlessly add them to your phone.
                    </dd>
                </div>
                <div key='document' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaFileSignature className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Digital Document Completion and Management
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Say goodbye to the hassle of paper forms. Our
                        platform guides students to complete all required documents according to your training agency's
                        guidelines, be it the RSTC Medical Form, Continuing Education Administration Document, or any
                        other. And with the convenience of electronic signatures, paperwork becomes a breeze. Once
                        finalized, these documents are immediately emailed to both the student and you, ensuring
                        everyone has the necessary paperwork at their fingertips. Plus, as an instructor, you'll have
                        all documents neatly organized and readily accessible in your portal, streamlining your
                        preparation for courses and dives.
                    </dd>
                </div>
                <div key='invoicing' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaFileInvoiceDollar className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Simplified Invoicing
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Juggling spreadsheets and manual invoicing
                        can detract from your passion for diving. Our solution? Dive more and let us handle the grind.
                        For independent contractor instructors tied to dive shops, we know the importance (and sometimes
                        the headache) of accurate invoicing. With our platform, those painstakingly detailed
                        spreadsheets become a thing of the past.<br/><br/>

                        Select the student certifications or events you wish to invoice, and with a simple click, our
                        system populates the required spreadsheets for you. Invoicing becomes swift, accurate, and
                        hassle-free, ensuring you're paid promptly for your expertise and dedication.
                    </dd>
                </div>
                <div key='revenue' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaHandHoldingUsd className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Unlock Hidden Revenue
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">In the world of scuba, sometimes the most
                        valuable revenue streams are the subtle ones. It's not always easy (or timely) to ask for tips,
                        solicit reviews, or even market your branded merchandise like t-shirts. Whether it's due to the
                        discomfort of the ask or the bustling post-dive rush, these opportunities can often slip through
                        the net.<br/><br/>

                        Our platform is designed to capture this lost potential. When divers revisit your website for
                        photos or dive details, they're presented with a discreet nudge: an opportunity to express their
                        gratitude with a tip, leave a glowing 5-star review, or even snag that fantastic t-shirt they
                        might've missed out on earlier. Turn every interaction into a chance to bolster your revenue and
                        enhance your brand's reputation.
                    </dd>
                </div>
                <div key='communication' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <MdMessage className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Seamless Communication
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Staying connected with groups of students can
                        feel like navigating a maze, especially when spanning various devices and mobile apps. With our
                        platform, that maze becomes a straight path. Need to relay directions, remind students about
                        equipment, or announce schedule tweaks? Or perhaps after an exhilarating dive, you're eager to
                        share dive specifics, like location and depth, or even showcase captivating photos taken during
                        the adventure. Our system streamlines all this communication.<br/><br/>

                        But it doesn't stop there. Every interaction draws your students back to your website, where
                        they can explore upcoming opportunities and bond with fellow divers. Enhance communication,
                        foster community, and keep your students engaged and informed.
                    </dd>
                </div>
                <div key='certification' className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaCertificate className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Effortless Certification Management
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Drowning in paperwork and meticulous
                        certification processes? Streamline your tasks with our platform. We understand the time drain
                        of constantly verifying documents, juggling spreadsheets, and wrestling with specific photo
                        needs for certification cards. Here's how we address these challenges:<br/><br/>

                        Our system vigilantly monitors certification prerequisites, ensuring no box goes unchecked. Need
                        to transfer student information to your training agency's website? With our setup, copying and
                        pasting become a seamless experience. And those stringent photo requirements? We've got you
                        covered. Our platform automatically tailors them to fit the exact specifications for submission.
                        <br/><br/>

                        Spend less time on administrative chores and more time underwater, doing what you love.
                    </dd>
                </div>
                <div key='opportunities' className="relative pl-16 pb-12">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div
                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                            <FaChair className="h-6 w-6 text-white" aria-hidden="true"/>
                        </div>
                        Maximize Your Opportunities, Minimize Empty Seats
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">Every empty seat is a missed opportunity,
                        whether you're on a dive shop salary, working as an independent contractor, or running your own
                        venture. Our platform changes the game by shifting from broad-brush marketing to
                        precision-targeted outreach. Here's how it works:<br/><br/>

                        Once you've keyed in your schedule, be it for an upcoming charter, an Advanced Open Water
                        course, or any other event, the system gets to work. It identifies and connects with past
                        students, nudging those who are the best fit for your upcoming sessions. Hosting a rescue course
                        and need recent rescue diver grads to assist? Let the platform bridge the communication
                        gap.<br/><br/>

                        With our platform, you're not just filling seats; you're curating experiences with the right
                        participants, ensuring higher engagement, better learning, and enhanced earnings.
                    </dd>
                </div>
            </dl>
        </div>

    );
};


export default ProspectiveInstrGoalsSection;