import React, {useEffect} from 'react';
import HeroCarouselPADI from "./HeroCarouselPADI";
// import HeroCarouselOther from "./HeroCarouselOther";
import HeroCarouselUnknown from "./HeroCarouselUnknown";
import {useSelector, useDispatch} from "react-redux";
import {setLastPage} from "../redux/navSlice";
import {checkOrgExists} from "./sharedHooks";

const HeroCarouselWrapper = () => {
    const instrDetails = useSelector((state) => state.instrDetails.instrDetails);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLastPage(window.location.pathname));
    }, [dispatch]);

    if (!instrDetails || !Array.isArray(instrDetails.pro_level_info) || instrDetails.pro_level_info.length === 0) {
        return <HeroCarouselUnknown/>;
    }

    // TODO: Change HeroCarouselOther to whatever organization we add next, then continue adding
    //  the final else should be HeroCarouselUnknown (investigate what <HeroCarouselOther> was for)
    if (checkOrgExists(instrDetails, 'PADI')) {
        return <HeroCarouselPADI/>
    } else {
        return <HeroCarouselUnknown/>
    }
}

export default HeroCarouselWrapper;