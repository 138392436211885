import React, {useEffect, useState} from 'react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Combobox} from '@headlessui/react'
import {MdEventBusy, MdSave, MdCancel} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {TbDatabaseEdit} from "react-icons/tb";
import {fetchCourses} from "../redux/coursesSlice";
import {eventChanged} from "../redux/eventsSlice";
import {fetchCustomerTypes} from "../redux/customerTypesSlice";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import '../styles/react-datetime-picker.css';
import {fetchForms} from "../redux/formsSlice";
import withAuth from "./withAuth";
import ParticipationCriteriaForm from "../components/ParticipationCriteriaForm";
import {format} from "date-fns";
import {post} from "aws-amplify/api";
import {fetchEventTemplates} from "../redux/eventTemplatesSlice";

const EditEventForm = ({
                           selectedEvent,
                           onExitForm,
                           onManageLocations,
                           onManageEventTemplates,
                       }) => {
    const [errorInfo, setErrorInfo] = useState(null);
    const locations = useSelector((state) => state.locations?.locations);
    const eventTemplates = useSelector((state) => state.eventTemplates?.eventTemplates);
    const [locationsQuery, setLocationsQuery] = useState('')
    const [eventTemplatesQuery, setEventTemplatesQuery] = useState('')
    const [isSaving, setIsSaving] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [formStatus, setFormStatus] = useState(null);
    const [formData, setFormData] = useState(selectedEvent);
    const instrTimeZone = useSelector((state) => state.instrDetails?.instrDetails?.timezone ?? 'Etc/UTC');
    const dispatch = useDispatch();
    const instr_id = useSelector((state) => state.instrDetails?.instrDetails?.instr_id);

    const [formErrors, setFormErrors] = useState({
        event_id: '',
        location_id: '',
        start_time: '',
        end_time: '',
        global_max_people: '',
        customer_title: '',
        is_cancelled: false,
        participation_criteria: []
    });

    const handleLocationChange = (fields) => {
        setFormData({
            ...formData,
            ...fields
        });
    }

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData]);

    const handleEventTemplateChange = (selectedEventTemplate) => {
        setFormData(prevFormData => {
            const dateSeparator = prevFormData.start_time.includes('T') ? 'T' : ' ';
            const startDatePart = prevFormData.start_time.split(dateSeparator)[0];
            const endDatePart = prevFormData.end_time.split(dateSeparator)[0];
            const newStartTime = `${startDatePart} ${selectedEventTemplate.start_time}`;
            const newEndTime = `${endDatePart} ${selectedEventTemplate.end_time}`;
            return {
                ...prevFormData,
                instr_title: selectedEventTemplate.instr_title,
                start_time: newStartTime,
                end_time: newEndTime,
                global_max_people: selectedEventTemplate.global_max_people,
                participation_criteria: selectedEventTemplate ? selectedEventTemplate.participation_criteria : null,
                customer_title: selectedEventTemplate.customer_title,
                event_template_id: selectedEventTemplate.event_template_id,
                custom_msg: null
            };
        });
    };

    const handleTopLevelChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value
        });
        // console.log('formData: ', formData);
    };

    const handleDateChange = (field, date) => {
        // console.log('date: ', date);
        const dateString = format(date, "yyyy-MM-dd HH:mm:ss", { timeZone: 'UTC'});
        handleTopLevelChange(field, dateString);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const filteredLocations =
        locationsQuery === ''
            ? locations
            : locations.filter((location) => {
                return location.location_name
                    ? location.location_name.toLowerCase().includes(locationsQuery.toLowerCase())
                    : false;
            })

    const filteredEventTemplates =
        eventTemplatesQuery === ''
            ? eventTemplates
            : eventTemplates.filter((eventTemplate) => {
                return eventTemplate.instr_title
                    ? eventTemplate.instr_title.toLowerCase().includes(eventTemplatesQuery.toLowerCase())
                    : false;
            })

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);

        // console.log('formData: ', formData);
        let errors = validate(formData);

        if (Object.keys(errors).length > 0) {
            console.log('errors: ', errors);
            setFormErrors(errors);
            setFormStatus({error: 'Some information was missing or invalid.  Please check again.'})
            setIsSaving(false);
        } else {
            setFormErrors({})
            const apiName = 'InstrOfScubaAPI'
            const path = '/save-event'
            const options = {
                method: 'POST',
                body: {formData, instrTimeZone, hostname: window.location.hostname},
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            try {
                const {body} = await post({apiName, path, options}).response;
                const data = await body.json();
                if (data?.failed) {
                    setFormStatus({error: data.message})
                } else {
                    setFormStatus({success: 'Your information has been saved.'})
                    dispatch(eventChanged());
                    setTimeout(() => {
                        onExitForm();
                    }, 3000);
                }
            } catch (error) {
                setErrorInfo('handleSaveEvent - /save-event\n' + error);
            }
        }

        setIsSaving(false);
    };

    useEffect(() => {
        dispatch(fetchCourses());
        dispatch(fetchCustomerTypes());
        dispatch(fetchForms());
        if (instr_id) {
            dispatch(fetchEventTemplates({instr_id}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // setFormErrors({});
        setFormStatus(null);
    }, [formData]);

    const handleCancelEvent = async (e) => {
        e.preventDefault();
        setIsCancelling(true);

        setFormErrors({})
        const apiName = 'InstrOfScubaAPI'
        const path = '/save-event'
        const eventData = {
            event_id: formData.event_id,
            is_cancelled: true
        }
        const options = {
            method: 'POST',
            body: {formData: eventData, hostname: window.location.hostname},
            headers: {
                'Content-Type': 'application/json',
            }
        }

        try {
            await post({apiName, path, options});
            onExitForm();
        } catch (error) {
            setErrorInfo('handleSaveEvent - /save-event\n' + error);
        }

        setIsCancelling(false);
    }


    function validate(formData) {
        let errors = {};
        if (!formData.customer_title) {
            errors.customer_title = 'Event Title is required';
        }

        if (!formData.event_template_id) {
            errors.event_template_id = 'Event Template is required';
        }

        if (!formData.location_id) {
            errors.location_id = 'Location is required';
        }

        if (!formData.start_time) {
            errors.start_time = 'Start Time is required';
        }

        if (!formData.end_time) {
            errors.end_time = 'End Time is required';
        }

        if (!formData.global_max_people) {
            errors.global_max_people = 'Maximum Participants is required';
        }

        if (formData.participation_criteria.length === 0) {
            errors.participation_criteria = 'At least one Customer Type is required';
        }

        if (formData.participation_criteria.length > 0) {
            console.log(formData);
            formData.participation_criteria.forEach((criteria, index) => {
                if (!criteria.customer_type) {
                    errors[`customer_type_${index}`] = 'Customer Type is required';
                }

                const price = parseFloat(criteria.price === null || criteria.price === '' ? 0 : criteria.price);
                if (Number.isNaN(price) || price < 0) {
                    errors[`price_${index}`] = 'Price is required and must be a non-negative number.';
                }

                const max_people = parseInt(criteria.max_people);
                if (typeof max_people !== 'number' || Number.isNaN(max_people) || max_people < 1) {
                    errors[`max_people_${index}`] = 'Max People must not be zero';
                }

                const min_people = parseInt(criteria.min_people === null || criteria.min_people === '' ? 0 : criteria.min_people);
                if (Number.isNaN(min_people) || min_people < 0 || min_people > max_people) {
                    errors[`min_people_${index}`] = 'Min People must be greater than 0 and no more than Max People';
                }

                const gear_factor = parseFloat(criteria.gear_factor);
                if (typeof gear_factor !== 'number' || Number.isNaN(gear_factor) || gear_factor < 1) {
                    errors[`gear_factor_${index}`] = 'Gear Factor must be 1 or greater';
                }
            });
        }

        return errors;
    }

    if (errorInfo) {
        throw errorInfo;
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Universal Event Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information applies to all customers types. Make the Event Title something catchy without
                        including location and associated courses as that information will be displayed along side the
                        event title. If you can make it unique per combination of Customer Types below, even better, as
                        you can quickly add new events using previous Event Title's as the template. Think about Shore
                        vs. Boat, number of tanks per person, and perhaps which boat if you use multiple boats. This
                        will significantly help reduce data entry when adding new events once you have unique
                        configurations of customers saved to Event Title/Courses/Location templates. <u>Maximum
                        Participants</u> includes all Customer Types combined, with no adjustments for Gear Factor(s).
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                        <div className="sm:col-span-3 sm:col-start-1">
                            <Combobox as="div" value={formData.instr_title || ''}
                                      onChange={(newVal) => {
                                          const selectedEventTemplate = filteredEventTemplates.find(et => et.instr_title === newVal);
                                          if (selectedEventTemplate) {
                                              handleEventTemplateChange(selectedEventTemplate);
                                          }
                                      }}
                            >
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                    Event Template
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(title) => setEventTemplatesQuery(title.target.value)}
                                        displayValue={(title) => title}
                                    />
                                    <Combobox.Button
                                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>

                                    {filteredEventTemplates?.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredEventTemplates.map((et) => (
                                                <Combobox.Option
                                                    key={et.instr_title}
                                                    value={et.instr_title}
                                                    className={({focus: isFocused}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            isFocused ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        )
                                                    }

                                                >
                                                    {({focus: isFocused, selected}) => (
                                                        <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{et.instr_title}</span>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        isFocused ? 'text-white' : 'text-indigo-600'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-3">
                            <button
                                type="button"
                                className="sm:mt-8 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={onManageEventTemplates}
                            >
                                <TbDatabaseEdit className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Manage Templates
                            </button>
                        </div>

                        <div className="sm:col-span-3">
                            <Combobox as="div" value={formData.location_name}
                                      onChange={(newVal) => {
                                          const selectedLocation = filteredLocations.find(loc => loc.location_name === newVal);
                                          if (selectedLocation) {
                                              handleLocationChange({
                                                  location_id: selectedLocation.location_id,
                                                  location_name: selectedLocation.location_name
                                              });
                                          }
                                      }}
                            >
                                <Combobox.Label
                                    className={`block text-sm font-medium leading-6 ${formErrors.location_id ? 'text-red-900' : 'text-gray-900'}`}>
                                    Location
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 ${formErrors.location_id ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                        onChange={(event) => setLocationsQuery(event.target.value)}
                                        displayValue={(location) => location}
                                    />
                                    <Combobox.Button
                                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </Combobox.Button>

                                    {filteredLocations.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredLocations.map((location) => (
                                                <Combobox.Option
                                                    key={location.location_id}
                                                    value={location.location_name}
                                                    className={({focus}) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        )
                                                    }
                                                >
                                                    {({focus, selected}) => (
                                                        <>
                                                            <span
                                                                className={classNames('block truncate', selected && 'font-semibold')}>{location.location_name}</span>

                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        focus ? 'text-white' : 'text-indigo-600'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-3">
                            <button
                                type="button"
                                className="sm:mt-8 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={onManageLocations}
                            >
                                <TbDatabaseEdit className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                Manage Locations
                            </button>
                        </div>

                        <div className="sm:col-span-4">
                            {/* Start DateTime Picker */}
                            <label htmlFor="start_datetime"
                                   className={`block text-sm font-medium leading-6 ${formErrors.start_time ? 'text-red-900' : 'text-gray-900'}`}
                            >
                                Start Time ({instrTimeZone})
                            </label>
                            <div className="mt-2">
                                <DateTimePicker
                                    name="start_datetime"
                                    value={formData.start_time ? new Date(formData.start_time) : null}
                                    onChange={(date) => handleDateChange('start_time', date)}
                                    disableClock={true}
                                    id="start_datetime"
                                    className={`block w-full rounded-md border-0 px-1 py-1 bg-white ${formErrors.start_time ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            {/* End DateTime Picker */}
                            <label htmlFor="end_datetime"
                                   className={`block text-sm font-medium leading-6 ${formErrors.end_time ? 'text-red-900' : 'text-gray-900'}`}
                            >
                                End Time ({instrTimeZone})
                            </label>
                            <div className="mt-2">
                                <DateTimePicker
                                    name="end_datetime"
                                    value={formData.end_time ? new Date(formData.end_time) : null}
                                    onChange={(date) => handleDateChange('end_time', date)}
                                    disableClock={true}
                                    id="end_datetime"
                                    className={`block w-full rounded-md border-0 px-1 py-1 bg-white ${formErrors.end_time ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="global_max_people"
                                   className={`block text-sm font-medium leading-6 ${formErrors.global_max_people ? 'text-red-900' : 'text-gray-900'}`}>
                                Maximum Participants (weighted)
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="global_max_people"
                                    value={formData.global_max_people}
                                    onChange={(e) => handleTopLevelChange('global_max_people', e.target.value)}
                                    id="global_max_people"
                                    className={`block w-full rounded-md border-0 py-1.5 ${formErrors.global_max_people ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <button
                                type="button"
                                className={`${isSaving || isSaving ? 'cursor-not-allowed opacity-50' : ''} inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
                                onClick={handleCancelEvent}
                                disabled={isCancelling || isSaving}
                            >
                                <MdEventBusy className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                                {isCancelling ? 'Cancelling...' : 'Cancel Event'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="sm:col-span-full">
                        <label htmlFor="customer_title"
                               className={`block text-base font-semibold leading-7 ${formErrors.customer_title ? 'text-red-900' : 'text-gray-900'}`}>
                            Event Title (shown to customers)
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                id="customer_title"
                                name="customer_title"
                                value={formData.customer_title || ''}
                                onChange={(e) => handleTopLevelChange('customer_title', e.target.value)}
                                className={`block w-full rounded-md border-0 py-1.5 ${formErrors.customer_title ? 'text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400'} focus:ring-indigo-600 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6}`}
                            />
                        </div>
                    </div>

                    <div className="mt-4 sm:col-span-full">
                        <label htmlFor="custom_msg"
                               className="text-base font-semibold leading-7 text-gray-900">
                            Custom Message (optional)
                        </label>
                        <div className="mt-2.5">
                          <textarea
                              name="custom_msg"
                              id="custom_msg"
                              value={formData.custom_msg || ''}
                              onChange={(e) => handleTopLevelChange('custom_msg', e.target.value)}
                              rows={4}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                    </div>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        This message will be displayed on the Event Details page, in addition to all information stored
                        in your template. You can use this to communicate special instructions, or to override
                        information in your template. If you have a message that is the same for all events, consider
                        adding it to your template instead.
                    </p>
                </div>
                <ParticipationCriteriaForm
                    formData={formData}
                    setFormData={setFormData}
                    formErrors={formErrors}
                    setFormStatus={setFormStatus}
                />
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="sm:col-span-2">
                        <button
                            type="submit"
                            disabled={isSaving || isCancelling}
                            className={`mr-2 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${(formStatus && (formStatus.error)) || isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
                            // No onClick as this triggers handleSubmit
                        >
                            <MdSave className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            onClick={onExitForm}
                        >
                            <MdCancel className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
                            Do NOT Save
                        </button>
                    </div>
                    {
                        formStatus && (formStatus.error ?
                                <div
                                    className="w-full rounded-md bg-red-200 text-red-500 text-center font-bold py-2 mt-2">{formStatus.error}</div>
                                :
                                <div
                                    className="w-full rounded-md bg-green-200 text-green-500 text-center font-bold py-2 mt-2">{formStatus.success}</div>

                        )
                    }
                </div>
            </div>
        </form>
    )
        ;
};

export default withAuth(EditEventForm);
