import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {get, post} from "aws-amplify/api";

const sumPrices = (products) => {
    return products.reduce((acc, product) => acc + product.price, 0);
};

const calculateTaxes = (discountedTotal, taxRate) => {
    return parseFloat((discountedTotal * taxRate).toFixed(2));
};

const sumDiscounts = (products) => {
    return products.reduce((acc, product) => acc + (product.price - product.discountedPrice), 0);
};

const sumAllTaxes = (products) => {
    return products.reduce((acc, product) => acc + product.taxes, 0);
};

const calculateTotal = (sub, discounts, taxes, shipping) => {
    return sub - discounts + taxes + shipping;
};

const aggregateDiscountCodes = (products) => {
    return products.reduce((acc, product) => {
        if (product.discount && product.discount.code) {
            acc.push(product.discount.code);
        }
        return acc;
    }, []);
};

const calculateDiscountedPrice = (product) => {
    let price = parseFloat(product.price);

    if (product.discount) {
        if (product.discount.type === 'percentage') {
            price = price - (price * (parseFloat(product.discount.value) / 100));
        } else if (product.discount.type === 'fixed') {
            price = price - parseFloat(product.discount.value);
        }
    }

    return price
};

export const fetchSubscriptionDetails = createAsyncThunk(
    'instrSubscription/fetchSubscriptionDetails',
    async (_, {rejectWithValue}) => {
        try {
            const apiName = 'InstrOfScubaAPI';
            const path = '/manage-subscription';
            const options = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            };
            const {body} = await get({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateSubscriptionDetails = createAsyncThunk(
    'instrSubscription/updateSubscriptionDetails',
    async ({post_body}, {rejectWithValue}) => {
        // new_tier_id is the new subscriptions.id (integer), having already been stripped of 'subscriptions-' string.
        // This updates local DB subscriptions table only as no payments or stripe integration required.
        try {
            const apiName = 'InstrOfScubaAPI';
            const path = '/manage-subscription';
            const options = {
                body: post_body,
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const {body} = await post({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSubDiscountDetails = createAsyncThunk(
    'instrSubscription/fetchSubDiscountDetails',
    async ({instr_id, code, productIds}, {rejectWithValue}) => {
        try {
            const apiName = 'InstrOfScubaAPI'
            const path = '/validate-discount-code'
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                queryParams: {discount_code: code, instr_id: instr_id, product_ids: productIds}
            };

            const {body} = await get({apiName, path, options}).response;
            return await body.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const instrSubscriptionSlice = createSlice({
    name: 'instrSubscription',
    initialState: {
        selectedPlan: {
            id: null,
            name: null,
            price: 0,
            discount: {code: null, type: null, rate: 0, value: 0},
            discountedPrice: 0,
            taxRate: 0,
            taxCalculated: 0,
            total: 0,
            frequency: null,
            imageSrc: null,
            imageAlt: null,
            description: null,
        },
        paymentGateway: {
            instr_id: 1,
            products: [],
            subTotal: 0.00,
            taxesTotal: 0.00,
            shippingTotal: 0.00,
            totalTotal: 0.00,
            totalDiscounts: 0.00,
            discountStatus: 'idle',
            discountError: null,
            discountCodes: []
        },
        subscriptionDetails: {
            subscription_id: null,
            subscription_exp: null,
            name: null,
            price: null,
            frequency: null,
            subscription_price_lock: null,
            subscription_id_at_expiry: null
        },
        status: 'idle',
        error: null,
    },
    reducers: {
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
            state.paymentGateway.products = [{
                id: action.payload.id || '',
                title: action.payload.name,
                price: action.payload.price,
                discountedPrice: action.payload.price,
                imageSrc: action.payload.imageSrc,
                imageAlt: action.payload.imageAlt,
                discount: {code: null, type: null, rate: 0, value: 0},
                shipping: 0.00,
                tax_rate: action.payload.tax_rate,
                taxes: calculateTaxes(action.payload.price, action.payload.tax_rate),
                subtitle1: action.payload.frequency,
                subtitle2: '',
                total: calculateTotal(action.payload.price, 0.00, calculateTaxes(action.payload.price, action.payload.tax_rate), 0.00)
            }];
            instrSubscriptionSlice.caseReducers.calculateTotals(state, action);
            state.paymentGateway.discountCodes = [];
        },
        applyDiscounts: (state, action) => {
            const {type, discount_value, discount_type, code} = action.payload;

            if (type === 'success') {
                const product = state.paymentGateway.products[0];
                if (product) {
                    const discount_value_float = parseFloat(discount_value);
                    product.discount = {
                        type: discount_type,
                        value: Math.round(discount_value_float * 100),
                        code: code
                    };
                }

                product.discountedPrice = calculateDiscountedPrice(product);
                product.taxes = calculateTaxes(product.discountedPrice, product.tax_rate);
                product.total = product.discountedPrice + product.taxes;
                state.paymentGateway.discountCodes = aggregateDiscountCodes(state.paymentGateway.products);
            }
        },
        calculateTotals: (state, action) => {
            const pg = state.paymentGateway;
            pg.subTotal = sumPrices([pg.products[0]]);
            pg.totalDiscounts = sumDiscounts([pg.products[0]]);
            pg.taxesTotal = sumAllTaxes([pg.products[0]]);
            pg.shippingTotal = 0.00;
            pg.totalTotal = calculateTotal(pg.subTotal, pg.totalDiscounts, pg.taxesTotal, pg.shippingTotal);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptionDetails.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchSubscriptionDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptionDetails = action.payload;
                state.error = null;
            })
            .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateSubscriptionDetails.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateSubscriptionDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subscriptionDetails = action.payload;
                state.error = null;
            })
            .addCase(updateSubscriptionDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchSubDiscountDetails.pending, (state) => {
                state.paymentGateway.discountStatus = 'loading';
                state.paymentGateway.discountError = null;
            })
            .addCase(fetchSubDiscountDetails.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.type === 'success') {
                    state.paymentGateway.discountStatus = 'succeeded';
                    state.paymentGateway.modalContent = null;
                } else {
                    state.paymentGateway.discountStatus = 'failed';
                    state.paymentGateway.modalContent = {
                        type: 'error',
                        title: payload.title,
                        body: payload.message,
                        buttons: [
                            {
                                text: 'Close',
                                action: 'close',
                                className: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600'
                            }
                        ]
                    };
                }
                state.paymentGateway.discountError = null;
                instrSubscriptionSlice.caseReducers.applyDiscounts(state, action);
                instrSubscriptionSlice.caseReducers.calculateTotals(state, action);
            })
            .addCase(fetchSubDiscountDetails.rejected, (state, action) => {
                state.paymentGateway.discountStatus = 'failed';
                state.paymentGateway.discountError = action.payload;
            });
    },
});

export const {setSelectedPlan} = instrSubscriptionSlice.actions;
export default instrSubscriptionSlice.reducer;