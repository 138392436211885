import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {fetchStudents} from '../redux/studentsSlice';
import {Menu, Transition} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'
import {LoadingSpinner} from "./LoadingSpinner";
import {Link} from "react-router-dom";
import {toZonedTime} from "date-fns-tz";
import {isAfter, parseISO} from "date-fns";
import ContactIcons from "../components/ContactIcons";
import StudentListButtons from "../components/StudentListButtons";
import SearchInput from "../components/SearchInput";
import {getImageUrl} from "../utils/sanitizeFunctions";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StudentList() {
    const dispatch = useDispatch();
    const {studentList, status, error} = useSelector((state) => state.students);
    const instrTimeZone = useSelector((state) => state.instrDetails?.instrDetails?.timezone ?? 'Etc/UTC');
    const nowInInstructorTz = toZonedTime(new Date(), instrTimeZone);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState([]);
    const proCertifications = ['PADI-DM', 'PADI-OWSI', 'PADI-MSDT', 'PADI-IDCSI', 'PADI-MI', 'PADI-CD'];

    const matchesSearchTerm = (student, searchTerm) => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const matchesText = (text) => text.toLowerCase().includes(lowerSearchTerm);

        if (matchesText(student.first_name) || matchesText(student.middle_init || '') || matchesText(student.last_name) ||
            matchesText(student.preferred_email) || matchesText(student.phone) || matchesText(student.city) || matchesText(student.state)) {
            return true;
        }

        if (student.certifications?.some(cert => matchesText(cert.course_code) || matchesText(cert.cert_number || ''))) {
            return true;
        }

        if (student.course_registrations?.some(reg => matchesText(reg.course_code) || matchesText(reg.registration_status))) {
            return true;
        }

        return !!matchesText(student.highest_core_certification || '');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    const filteredStudentList = studentList.filter((student) => {
        if (searchTerm && !matchesSearchTerm(student, searchTerm)) {
            return false;
        }

        return filters.every((filter) => {
            switch (filter) {
                case "Pros":
                    return student.certifications?.some(cert => proCertifications.includes(cert.course_code));
                case "Unverified Cert":
                    return student.certifications?.some(cert => !cert.verified);
                case "Active Course":
                    return student.course_registrations?.some(reg => reg.registration_status === 'Active');
                case "Upcoming Event":
                    return student.event_attendees?.some(att => {
                        const eventStartTimeInInstructorTz = toZonedTime(parseISO(att.start_time), instrTimeZone);
                        return isAfter(eventStartTimeInInstructorTz, nowInInstructorTz) &&
                            ['Confirmed', 'Registered', 'Wait list'].includes(att.status);
                    });
                case "Outstanding Charges":
                    return student.outstanding_charges > 0;
                case "Outstanding Forms":
                    return student.has_outstanding_forms;
                default:
                    return true;
            }
        });
    });

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchStudents());
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (status === 'failed') {
            throw new Error(`/get-student-list\n${error}`);
        }
    }, [status, error]);

    return (
        <>
            <LoadingSpinner isLoading={status === 'loading'}/>
            <div className="p-4">
                <SearchInput value={searchTerm} onChange={handleSearchChange}/>
            </div>
            <StudentListButtons onFilterChange={handleFilterChange}/>
            <ul className="divide-y divide-gray-100">
                {filteredStudentList.map((student) => {
                    const unverifiedCertsCount = student.certifications?.filter(certification => !certification.verified).length || 0;
                    const activeCoursesCount = student.course_registrations?.filter(reg => reg.registration_status === 'Active').length || 0;
                    const upcomingEventsCount = student.event_attendees?.filter(att => {
                        const eventStartTimeInInstructorTz = toZonedTime(parseISO(att.start_time), instrTimeZone);
                        return isAfter(eventStartTimeInInstructorTz, nowInInstructorTz) &&
                            ['Confirmed', 'Registered', 'Wait list'].includes(att.status);
                    }).length || 0;
                    const outstandingChargesTotal = student.outstanding_charges || 0;
                    const outstandingForms = student.has_outstanding_forms;
                    const lastActiveDate = student.last_active;
                    const highestCoreCertification = student.highest_core_certification;
                    const registeredCount = student.registered_count || 0;
                    const confirmedCount = student.confirmed_count || 0;
                    const waitListCount = student.wait_list_count || 0;
                    const cancelledCount = student.cancelled_count || 0;
                    const lateOrNoShowCount = student.late_or_no_show_count || 0;

                    return (
                        <li key={student.id} className="flex items-center justify-between gap-x-6 py-5 px-4">
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <img className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                         src={getImageUrl('userPortrait-' + student.id + '.jpeg')}
                                         alt=""/>
                                    <div>
                                        <p className="text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap">
                                            {student.first_name} {student.middle_init ? student.middle_init : ''} {student.last_name}
                                        </p>
                                        <div className="flex gap-2 mt-0.5">
                                            <ContactIcons email={student.preferred_email} phone={student.phone}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap gap-2 mt-0.5">
                                        {unverifiedCertsCount > 0 && (
                                            <Link to={`/certifications/${student.id}`}
                                                  className="text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                {unverifiedCertsCount} Unverified
                                                Certification{unverifiedCertsCount > 1 ? 's' : ''}
                                            </Link>
                                        )}
                                        {activeCoursesCount > 0 && (
                                            <Link to={`/student-courses/${student.id}`}
                                                  className="text-blue-600 bg-blue-50 ring-blue-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                {activeCoursesCount} Active Course{activeCoursesCount > 1 ? 's' : ''}
                                            </Link>
                                        )}
                                        {upcomingEventsCount > 0 && (
                                            <Link to={`/student-events/${student.id}`}
                                                  className="text-green-600 bg-green-50 ring-green-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                {upcomingEventsCount} Upcoming Event{upcomingEventsCount > 1 ? 's' : ''}
                                            </Link>
                                        )}
                                        {outstandingChargesTotal > 0 && (
                                            <Link to={`/outstanding-charges/${student.id}`}
                                                  className="text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                Outstanding Charges: ${outstandingChargesTotal}
                                            </Link>
                                        )}
                                        {outstandingForms && (
                                            <Link to={`/outstanding-forms/${student.id}`}
                                                  className="text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                Outstanding Forms
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-800">
                                    <p className="flex-wrap">
                                        {lastActiveDate && (
                                            <span>
                                                Recency: <time
                                                dateTime={lastActiveDate}>{new Date(lastActiveDate).toLocaleDateString()}</time>
                                            </span>
                                        )}
                                    </p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                        <circle cx={1} cy={1} r={1}/>
                                    </svg>
                                    <p className="flex-wrap">{highestCoreCertification}</p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                        <circle cx={1} cy={1} r={1}/>
                                    </svg>
                                    <p className="flex-wrap">Events: {registeredCount} Registered, {confirmedCount} Confirmed, {waitListCount} Wait
                                        list, {cancelledCount} Cancelled, {lateOrNoShowCount} Late/No-Show</p>
                                </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                                <Link
                                    to={student.href}
                                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                                >
                                    Details<span className="sr-only">, {student.name}</span>
                                </Link>
                                <Menu as="div" className="relative flex-none">
                                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            <Menu.Item>
                                                {({focus}) => (
                                                    <Link
                                                        to={`/certifications/${student.id}`}
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Certs<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({focus}) => (
                                                    <Link
                                                        to={`/student-courses/${student.id}`}
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Courses<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({focus}) => (
                                                    <Link
                                                        to={`/student-events/${student.id}`}
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Events<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({focus}) => (
                                                    <Link
                                                        to={`/student-charges/${student.id}`}
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Charges<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({focus}) => (
                                                    <Link
                                                        to="#"
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Forms<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item className="block sm:hidden">
                                                {({focus}) => (
                                                    <Link
                                                        to={student.href}
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                        )}
                                                    >
                                                        Details<span className="sr-only">, {student.name}</span>
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    )
}
