import React from 'react';
import {useSelector} from 'react-redux';
import {Authenticator, Button, ThemeProvider, useAuthenticator, View} from "@aws-amplify/ui-react";
import {myCustomAuthTheme} from "./sharedHooks";

const components = {
    SignUp: {
        Footer() {
            const {toSignIn} = useAuthenticator();

            return (
                <View textAlign="center" className="mb-4">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Already have an account? Sign In
                    </Button>
                </View>
            );
        },
    }
}

const withAuth = (WrappedComponent, options = {}) => {
    const {showInstrIntro = false, showStudentIntro = false, initialState = "signIn"} = options;

    return (props) => {
        const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

        return (
            <ThemeProvider theme={myCustomAuthTheme}>
                <div data-testid="with-auth">
                    {!isAuthenticated && (
                        <>
                            {showInstrIntro && (
                                <div>
                                    <p className="mt-6 text-lg leading-8 text-gray-600 mb-4">
                                        Thank you for considering our platform to elevate your scuba career! By creating
                                        an
                                        account,
                                        you open up a world of opportunities to grow your business and connect with
                                        students
                                        who are
                                        eager to learn. You'll have access to a suite of tools designed to automate
                                        administrative
                                        tasks, manage student progress, and more — all while showcasing your expertise
                                        through a
                                        personalized instructor profile or even your own dedicated website. This account
                                        is
                                        your
                                        launchpad: it simplifies scheduling, allows for easy invoicing, and lets you
                                        focus
                                        on what
                                        you do best — teaching. Let's get started and turn your skills into success
                                        stories,
                                        making you
                                        not just an instructor, but a sought-after expert in your field!
                                    </p>
                                </div>
                            )}
                            {showStudentIntro && (
                                <div>
                                    <p className="mt-6 text-lg leading-8 text-gray-600 mb-4">
                                        Welcome to our platform! By creating an account, you're taking the first step
                                        towards a more streamlined and enjoyable learning experience. As a student, your
                                        account will give you direct access to your instructor and make signing up for
                                        courses and events a breeze. You'll be able to track your progress, manage your
                                        schedule, and easily register for the classes that interest you. Plus, you'll
                                        have a
                                        secure place to store important documents like signed waivers and your scuba
                                        certifications, so you won’t need to worry about providing them again and again.
                                        Your account is designed to keep everything organized in one place, allowing you
                                        to
                                        focus on what really matters—your growth and enjoyment in your scuba journey.
                                        Let's
                                        dive in together and make the most of your underwater adventures!
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                    <Authenticator socialProviders={[]}
                                   initialState={initialState}
                                   components={components}>
                        {() => (
                            <WrappedComponent {...props} />
                        )}
                    </Authenticator>
                </div>
            </ThemeProvider>
        );
    };
};

export default withAuth;